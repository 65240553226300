import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';
import { BOOK_TYPE_RESOURCES, BOOK_TYPE_EXTRAS } from '../constants.js';

export const GetAllSections = ( bibleId, bookId, parent, parentCode, resourceId, navFrom, bookType ) => {  
    
    let sections = [];
    let notes = [];
    const isExtras = (bookType === BOOK_TYPE_EXTRAS);
    let sourceId = (navFrom === BOOK_TYPE_RESOURCES) ? resourceId : bookId;  
    let parentKey = (navFrom === BOOK_TYPE_RESOURCES) ? navFrom : parent;
    let {isMVP, modeParm} = getModeParm();
    let indexParm = (parentCode === undefined)?"":parentCode;
    let url = '';
    if (isExtras) {
      // Use "General" endpoint for extras
      url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/general/${sourceId}/${parent}/${indexParm}${modeParm}`
    } else {
      // Example of API endpoint to get all sections for first movement of Genesis:  /bibles/ENGSEB/GEN/movements/1
      url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/${sourceId}/${parent}/${indexParm}${modeParm}`
    }
    // console.log("GetAllSections", url);
    const { data, isPending, isError, error } = useQuery({
        queryKey: [bibleId,bookType,sourceId,parentKey,parentCode],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        enabled: true,
        staleTime: 60 * 60 * 1000,  // query is good for 1 hour
        refetchOnMount: false,
        refetchOnWindowFocus: false        
    });  

  if (!isPending && data && data.data) {
    if (isMVP) {
      if (data.data.sections) {
        sections = data.data.sections;
      } else if (data.data[0] && data.data[0].movements && data.data[0].movements[0] && data.data[0].movements[0].sections) {
        // For Resources
        sections = data.data[0].movements[0].sections;
      }
    } else {
      if (data.data[parent]) {
        let parents = data.data[parent];
        if (parents.length > 0) {
          sections = data.data[parent][0].sections;
          if (sections && sections.length > 1) {
            sections.sort((a, b) => a.section - b.section);
          }
          // Some movements have audio notes without a section - these are movement level notes and have to be treated differently
          notes = data.data[parent][0].audio_notes;
          if (notes && notes.length > 1) {
            notes.sort((a, b) => a.passage - b.passage);
          }
        }
      }
    }
  }

  return { sourceId, sections, notes, isPending, isError, error }
}
