import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';

export const GetLanguages = () => {
    let languages = [];
    let {modeParm} = getModeParm();
    const thisURL = `${process.env.REACT_APP_BASE_URL}/languages${modeParm}`;
    // console.log("GetLanguages: " + thisURL);
    const { data, isPending, isError, error } = useQuery({
        queryKey: ['languages'],
        queryFn: () => axiosClient.get(thisURL).then((res) => res.data),
        enabled: true
    });  

    // if (!isPending && data) {
    //   languages = data.data;        
    // }
    if (!isPending && data) {      
      languages =  data.data;
      if (languages.length > 1) {
        languages.sort((a, b) => (a.iso?.toUpperCase() > b.iso?.toUpperCase())?1:-1);
      }
    }

  return { languages, isPending, isError, error }
}
