
import PropTypes from "prop-types";
import React from "react";
import { LanguageLabel } from "../LanguageLabel";
import "./style.css";

export const ObtFooter = ({ breakpoint, className="" }) => {
  const currentYear = new Date().getFullYear();
  return (
    <div
      className={`OBT-footer ${breakpoint} ${className}`}
      data-breakpoints-mode={breakpoint === "tablet" ? "tablet" : breakpoint === "mobile" ? "mobile" : "desktop"}
    >
      {breakpoint === "desktop" && (
        <>
          <p className="footer-text">© {currentYear} SIL International, All rights reserved.</p>
          <div className="footer-links">
            <a className="footer-link" href={process.env.REACT_APP_TERMS_LINK} >Terms</a>
            <a className="footer-link" href={process.env.REACT_APP_PRIVACY_LINK} >Privacy</a>
            {/* <div className="footer-link">Cookies</div> */}
            <LanguageLabel />
          </div>
        </>
      )}

      {breakpoint === "tablet" && (
        <>
          <p className="footer-text">© {currentYear} SIL International, All rights reserved.</p>
          <div className="footer-links">
            <a className="footer-link" href={process.env.REACT_APP_TERMS_LINK} >Terms</a>
            <a className="footer-link" href={process.env.REACT_APP_PRIVACY_LINK} >Privacy</a>
            {/* <div className="footer-link">Cookies</div> */}
            <LanguageLabel />
          </div>
        </>
      )}
     
     {breakpoint === "mobile" && (
        <>
          <p className="footer-text">© {currentYear} SIL International, All rights reserved.</p>
          <div className="footer-links">
            <a className="footer-link" href={process.env.REACT_APP_TERMS_LINK} >Terms</a>
            <a className="footer-link" href={process.env.REACT_APP_PRIVACY_LINK} >Privacy</a>
            {/* <div className="footer-link">Cookies</div> */}
            <LanguageLabel />
          </div>
        </>
      )}
    </div>
  );
};

ObtFooter.propTypes = {
  breakpoint: PropTypes.oneOf(["desktop", "tablet", "mobile"]),
};
