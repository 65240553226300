import React from "react";
import useWindowWidth from "../../hooks/useWindowWidth";
import { ObtHeader } from "../../components/ObtHeader";
import { ObtFooter } from "../../components/ObtFooter";
import { ObtBibleButton } from "../../components/ObtBibleButton";
import { ObtViewButtons } from "../../components/ObtViewButtons";
import { Resources } from "../../components/Resources";
// import { AppData } from "../../components/AppContext";
import "./style.css";

// TBD:   Resources page is only used in the old demo version of akuo.  
//        This page can eventually be removed when we no longer wish to support the demo version.

export const ResourcesPage = ( ) => {
  const { dataBreakpointsMode } = useWindowWidth();
  // const { currentBible } = AppData();
  return (
    <div className="resources-page background-wallpaper" data-breakpoints-mode={dataBreakpointsMode}> 
      <div className="hero-header-section">
        <ObtHeader
          breakpoint={dataBreakpointsMode}
          className="obt-header-navigation-menu"
        />            
      </div>
      
      <div className={`frame-tabs-container ${dataBreakpointsMode}`}>
        <div className={`frame-tabs frame-tabs-${dataBreakpointsMode}`}>
          <ObtBibleButton />
          
          {dataBreakpointsMode === "desktop" && <div className={`heading-3 ${dataBreakpointsMode}`}>RESOURCES</div>}

          <div className="horizontal-tabs">          
            {/* <StoryMode className="story-mode-toggle" mode={storyMode} toggleMode={toggleMode}/> */}
            {/* <StoryMode className="story-mode-toggle" /> */}
            <div className="button">
              {/* <ObtViewButtons className="obt-view-buttons" state={viewState} toggleState={toggleViewState}/> */}
              <ObtViewButtons className="obt-view-buttons" />
            </div>
          </div>
        </div>
      </div>

      {dataBreakpointsMode !== "desktop" && <div className={`heading-3 ${dataBreakpointsMode}`}>RESOURCES</div>}

      <Resources className="obtresource" />                  
      <div>
        <br/>
        <br/>
        <br/>
      </div>

      <div className="obt-footer">        
        <div className="container-footer">
            <ObtFooter  breakpoint={dataBreakpointsMode} />
        </div>
      </div>
      {/* <FabPlay className="fab-play-button" bookName={currentResource}/> */}
    </div>
  );
};
