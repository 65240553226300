
import React from "react";
import { ElementCard } from "../ElementCard";
import { AppData } from "../../components/AppContext";
import { TRADITIONAL_MODE, BOOK_TYPE_EXTRAS } from '../../constants.js';
import { GetAllBooks } from "../../utils/GetAllBooks";
import "./style.css";

const placeholderCards = [  
  {
    book_id: "TMP1",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP2",
    name: "_",
    name_alt: "_"
  }
];

// Extras are "General" projects that should be treated the same way as Books 
export const Extras = ({ className }) => {
  const { viewState, storyMode, currentBible, setCurrentBook } = AppData();
  const bibleId = currentBible['id'];
  const { books, isPending, isError, error } = GetAllBooks(bibleId, BOOK_TYPE_EXTRAS);
  const isTraditionalMode = storyMode === TRADITIONAL_MODE;
  
  // TBD: Add better error handling
  if (isError) return 'An error has occurred: ' + error.message  

  return (
    <div className={`obtextras ${viewState} ${className}`}>
      <div className="frame-extras">

        {isPending && placeholderCards.map(book => {          
          return (
            <span className="skeleton-box" key={book.book_id}>
            <ElementCard
              key={book.book_id}
              cardCode={book.book_id}
              cardType={BOOK_TYPE_EXTRAS}
              cardAnimation = "shimmer"
              cardName= {isTraditionalMode ? book.name : book.name_alt}
              cardDuration="_"
              cardImageClassName={`card-image-${viewState}`}
              //cardImage
              cardAudioTitle={null}
              cardState={viewState}
              subheadingClassName="element-card-subheading"                                 
              showPlay={false}                   
              showDownload={false}                         
              overlapGroupClassName="instance-node"
              showAddToPlayList={false}
            />
            </span>
          )
        })}    
        

        {!isPending && books && books.map(book => {     
            return (
            <ElementCard
              key={book.book_id}
              cardCode={book.book_id}
              cardType={BOOK_TYPE_EXTRAS}
              cardName={ isTraditionalMode ? book.name : book.name_alt}
              cardDuration={book.duration?book.duration:"-:--"}
              cardImageClassName={`card-image-${viewState}`}
              cardImage={book.images[0]?book.images[0].url?book.images[0].url:null:null}
              cardAudioTitle={isTraditionalMode ? ( book.title_audio?book.title_audio[0]?.url?book.title_audio[0].url:null:null ) 
                                                : ( book.title_audio_alt?book.title_audio_alt[0]?.url?book.title_audio_alt[0].url:null:null ) }
              cardState={viewState}
              subheadingClassName="element-card-subheading"                                 
              showPlay={false}    // TBD: Enable play from book
              obtQuickPlayClassName={`card-quickplay-${viewState}`}
              showDownload={false}  // TBD: Enable download book         
              obtDownloadClassName={`card-download-${viewState}`}
              overlapGroupClassName="instance-node"
              navigateTo="/movements"
              setCurrentCardAction={() => setCurrentBook}
              showAddToPlayList={false}
            />
            )
          })}
      </div>      
    </div>
  );
}

