import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';
import { BOOK_TYPE_EXTRAS } from '../constants.js';

export const GetAllBooks = ( bibleId, bookType ) => {
    let books = [];
    let {isMVP, modeParm} = getModeParm();
    const isExtras = (bookType === BOOK_TYPE_EXTRAS);
    
    let url = '';
    // Extras pulls from General projects, otherwise pull Scripture projects from books endpoint
    if (isExtras) {
      if (isMVP) {
        url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/extras`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/general/books${modeParm}`;
      }
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/books${modeParm}`
    }
    
    
    // console.log("GetAllBooks: ", url);
    const { data, isPending, isError, error } = useQuery({
        queryKey: [bibleId,bookType],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        enabled: true
    });

    if (!isPending && !isError && (bibleId === undefined || bibleId === null)) {
      // The value "undefined" as bible_id parm does not generate an error, but we want to treat it as a 404
      // Force the 404 here as we can't catch prior to useQuery because useQuery hook cannot be conditionally reached
      let response = {status:404}
      return { books, isPending, isError:true, error:{message:"Not Found", response} }
    }

    // if (isError ) {
    //   if (error.response && [403,404,500].includes(error.response.status)) {
    //     message = "Select a Bible and Book Type from the menu to view the books."
    //   }
    //   return { books, isPending, isError:true, error:{message} }
    // }

    if (!isPending && data) {      
      books =  data.data;
      if (!isMVP && books.length > 1) {
        books.sort((a, b) => (a.book_order > b.book_order)?1:-1);
      }
      if (isMVP && books.length > 0) {
        // Assuming a single book for now
        books[0].book_id = "NVP";
        books[0].name_alt = books[0].name;
      }
    }

  // console.log("GetAllBooks:" , books);
  return { books, isPending, isError, error }
}
