import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getCategoryToggle, setLocalCategories } from "./categoryFunctions.js";
import { getModeParm }  from './getModeParm';

export const GetNoteCategories = (bibleId) => {
    // console.log("GetNoteCategories - bibleId: " , bibleId)
    let {modeParm} = getModeParm();
    const url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/note_categories${modeParm}`
    let categories = [];

    const { data, isPending, isError, error } = useQuery({
        queryKey: [bibleId,'note-categories'],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        enabled: true
    });  
    
    let isCategoryPending = (isPending)?true:false;

    if (!isPending && data) {
      // Clone the data 
      categories = JSON.parse(JSON.stringify(data.data));

      for (const category of categories) {
        // Add an extra element to track which categories are enabled
        // Use the last saved value from local storage if it exists
        category["toggle"] = getCategoryToggle(bibleId,category.id);
        //console.log("category toggle: ", category.id, category["toggle"] );
      }

      // Save the updated categories in the browser's local storage
      //console.log("GetNoteCategories: setLocalCategories ", bibleId, categories);
      setLocalCategories(bibleId, categories);
    }

  return { categories, isCategoryPending, isError, error }
}