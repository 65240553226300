
export const isApple = async () => {  
    if (navigator.userAgentData) {
      console.log("navigator.userAgentData", navigator.userAgentData)
      return navigator.userAgentData.getHighEntropyValues(['platform'])
        .then(ua => {
          return ua.platform.includes('macOS') || ua.platform.includes('iOS') || ua.platform.includes('iPadOS');
        });
    } else {
      console.log("navigator.userAgent", navigator.userAgent)
      // Fallback for browsers that do not support navigator.userAgentData
      const userAgent = navigator.userAgent.toLowerCase();
      return /macintosh|mac os x|iphone|ipad/.test(userAgent);
    }
}
