import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export const Portal = ( {setIsOpen, dimensions, children} ) => {
const [container, setContainer] = useState(null);
const newWindow = useRef(null);


  useEffect(() => {
    // Create container element on client-side
    setContainer(document.createElement("div"));
  }, []);

  useEffect(() => {
    function copyStyles(src, dest) {
      Array.from(src.styleSheets).forEach((styleSheet) => {
        const styleElement = styleSheet.ownerNode.cloneNode(true);
        styleElement.href = styleSheet.href;
        dest.head.appendChild(styleElement);
      });
      Array.from(src.fonts).forEach((font) => dest.fonts.add(font));
    }
    
    function windowLoaded(thisWindow) {
      // Save the new window reference
      newWindow.current = thisWindow;
  
      thisWindow.document.title = "Web Player"
    
      // Copy the styles for the popup window
      copyStyles(window.document, thisWindow.document);

      // Capture window close event
      thisWindow.addEventListener("beforeunload", closeWindowCleanup);

      // Append container to new window DOM
      thisWindow.document.body.appendChild(container);
      //webplayer.appendChild(container);
      
    }

    function closeWindowCleanup() {
      setIsOpen(false);
    }

    // When container is ready
    if (container) {
      let width = dimensions["width"]? dimensions["width"]:330;
      let height = dimensions["height"]? dimensions["height"]:400;
      let left = dimensions["left"]? dimensions["left"]:10;
      // Create window
      const windowFeatures = `width=${width}px,height=${height}px,left=${left},top=100`;
      let thisWindow = window.open(
        "",
        "webplayer",
        windowFeatures
      );
      
      console.log("window", thisWindow);

      if (thisWindow) {

        thisWindow.addEventListener('load', windowLoaded(thisWindow), true);           
      
      } else {
        // TBD: Alert user to disable popup blocker to view web player
        console.log("Can't open webplayer")
      } 

      // Return cleanup function
      return () => { 
        // On unmount close the window
        if (thisWindow) {
            thisWindow.close()
        }};
    }
  }, [container, setIsOpen, dimensions]);

  return container && createPortal(children, container);
};