
import React from "react";
import { AppData } from "../../components/AppContext";
import { VIEWSTATE_LARGE } from '../../constants.js';
import "./style.css";

export const ObtViewButtons = () => {
  const { viewState, toggleViewState } = AppData();  
  return (
    
    <label
      className={`obt-view-buttons ${viewState}`}>
        <input type="image" onClick={toggleViewState}
          src={viewState === VIEWSTATE_LARGE ? "/img/state-small.png" : "/img/state-large.png"}
          alt="View State"
          name="viewBtn" className="viewBtn submit" id="viewBtn" />      
    </label>
  );
};

ObtViewButtons.propTypes = {
  // state: PropTypes.oneOf([VIEWSTATE_LARGE, VIEWSTATE_SMALL]),
};
