
import React from "react";
import { ObtResourceCard } from "../ObtResourceCard";
import { AppData } from "../../components/AppContext";
import { VIEWSTATE_SMALL, BOOK_TYPE_BOOKS, BOOK_TYPE_EXTRAS } from '../../constants.js';
import { GetAllBooks } from "../../utils/GetAllBooks";
import "./style.css";

const placeholderCards = [  
  {
    book_id: "TMP1",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP2",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP3",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP4",
    name: "_",
    name_alt: "_"
  }
];

export const ObtResourcesExtras = ({ 
        className=""
 }) => {

  const { setCurrentBook, currentBible } = AppData();
  let viewState = VIEWSTATE_SMALL;
  let isTraditionalMode = true;
  const bibleId = currentBible['id'];  

  const { books, isPending, isError, error } = GetAllBooks(bibleId, BOOK_TYPE_EXTRAS);
  
  // Temp for testing loading ...
  // let isPending = true, books = [], isError = false, error = null;
   
  // TBD: Add better error handling
  if (isError) {
    let message = 'An error has occurred: ' + error.message
    if (error.response && [403,404,500].includes(error.response.status)) {
      message = "Select a Language and Bible from the menu to view the resources."
    } 
    return (
      <div className={`resources ${viewState} ${className}`}>        
        <div className="error-message">
          {message}
      </div></div>
    )
  }

  return (
    <>

        {isPending && placeholderCards.map(book => {          
          return (
            <span className="skeleton-box" key={book.book_id}>
            <ObtResourceCard
              key={book.book_id}
              cardCode={book.book_id}
              cardType={BOOK_TYPE_BOOKS}
              cardAnimation = "shimmer"
              cardName={ isTraditionalMode ? book.name : book.name_alt}
              cardDuration="_"
              cardImageClassName={`resource-image-${viewState}`}
              //cardImage
              cardAudioTitle={null}
              cardState={viewState}
              showPlay={false}     
              obtQuickPlayClassName={`resource-quickplay-${viewState}`}
              showDownload={false}           
              obtDownloadClassName={`resource-download-${viewState}`}
            />
            </span>
          )
        })}    
        

        {!isPending && books && books.map(book => {
            return (
            <ObtResourceCard
              key={book.book_id}
              cardCode={book.book_id}
              cardType={BOOK_TYPE_BOOKS}
              cardName={isTraditionalMode ? book.name : book.name_alt}
              cardDuration={book.duration?book.duration:"-:--"}
              cardImageClassName={`resource-image-${viewState}`}
              cardImage={book.images[0]?book.images[0].url?book.images[0].url:null:null}
              cardAudioTitle={isTraditionalMode ? ( book.title_audio?book.title_audio[0]?.url?book.title_audio[0].url:null:null ) 
                                                : ( book.title_audio_alt?book.title_audio_alt[0]?.url?book.title_audio_alt[0].url:null:null ) }
              cardState={viewState}
              showPlay={false}
              obtQuickPlayClassName={`resource-quickplay-${viewState}`}
              showDownload={false}   
              obtDownloadClassName={`resource-download-${viewState}`}
              setCurrentCardAction={() => setCurrentBook}
              isExtras={true}
            />
            )
          })}
      
    </>
  );
};
