import React, { useEffect,useRef } from "react";
import useFileDownloader from "../../hooks/useFileDownloader";
import useWindowWidth from "../../hooks/useWindowWidth";
import { trackPage } from "../../utils/analytics.js";
import { ObtButton } from "../../components/ObtButton";
import { ObtBibleButton } from "../../components/ObtBibleButton";
import { ObtHeader } from "../../components/ObtHeader";
import { HeroHeader } from "../../components/HeroHeader";
import { ObtFooter } from "../../components/ObtFooter";
import { ObtExpandAll } from "../../components/ObtExpandAll";
import { ObtViewButtons } from "../../components/ObtViewButtons";
import { PiChats, PiBellRinging } from "react-icons/pi";
// import { FabPlay } from "../../components/FabPlay";
import { StoryMode } from "../../components/StoryMode";
import { Books } from "../../components/Books";
import { ObtResources } from "../../components/ObtResources";
import { ObtResourcesExtras } from "../../components/ObtResourcesExtras";
import { Portal } from "../../components/Portal";
import { AudioPlaylist } from "../../components/AudioPlaylist";
import { GetNoteCategories } from "../../utils/GetNoteCategories";
import { setResourceOpen } from '../../utils/resourceOpenFunctions.js';
import { VIEWSTATE_SMALL } from '../../constants.js';
import { AppData } from "../../components/AppContext";
import "./style.css";

export const HomePage = ( ) => {  
  document.title = process.env.REACT_APP_NAME;
  const { dataBreakpointsMode } = useWindowWidth();
  // console.log('screenWidth', screenWidth, dataBreakpointsMode); 
  const { storyMode, currentBible, currentBook, dimensions,isOBTHelps,
          fileDownLoader, isOpenResource,setIsOpenResource,showPlayList, categoryList, isOpenPlayer,setIsOpenPlayer } = AppData();
  const sourceId = currentBook;
  const classPlayList = showPlayList ? "with-playlist" : "";

  // playList ref will be passed to both Sections and AudioPlaylist to keep track of the playlist and current track
  // const playListRef = useRef();

  // For file downloads
  const [downloadFile, downloaderComponentUI] = useFileDownloader();
  fileDownLoader.current = downloadFile;
  
  const bibleId = currentBible['id'];
  
  //const isOBTHelps = process.env.REACT_APP_NAME==="OBT Helps"?true:false;
  const BookHeading = isOBTHelps ? "RESOURCES" : "BOOKS";
  const linkToPlayStore = process.env.REACT_APP_GOOGLE_PLAY_URL?process.env.REACT_APP_GOOGLE_PLAY_URL:null;
  
  // TBD:  Use API info instead of hardcoding - bible metadata should provide - need to add to APM first 
  const bibleDetails = {obt_email: process.env.REACT_APP_OBT_EMAIL, seb_email: process.env.REACT_APP_SEB_EMAIL}

  /**
   * useEffect hook runs on page mount and executes the return() portion on unmount
   * Use to capture the page load metrics
   */
  const storyModeRef = useRef();
  storyModeRef.current = storyMode;
  useEffect(() => {
    const thisPage = "home";
    // console.log(thisPage + " mount");
    // Trigger Segment's page tracking with additional properties
    trackPage(thisPage, storyModeRef.current);
  }, []);

  // Category list determines which audio notes will be included in the playlist.  
  // const categoryList = useRef();
  // console.log("bibleId", bibleId)
  const { categories, isCategoryPending } = GetNoteCategories(bibleId, categoryList);
  if (!isCategoryPending && categories.length > 0) {
    categoryList.current = categories;
  }

  const clickExpandCollapse = (event, expandFlag) => {
    setResourceOpen('ALL', expandFlag, isOpenResource, setIsOpenResource);
    event.stopPropagation();
  }

  return (
    <>
    <div className={`home-page background-wallpaper ${dataBreakpointsMode} ${classPlayList}`} data-breakpoints-mode={dataBreakpointsMode}>

      <div className="home-left-wrapper">
        <div className="hero-header-section">
          <ObtHeader
            breakpoint={dataBreakpointsMode}
            className="obt-header-navigation-menu"
          />
          <HeroHeader
            breakpoint={dataBreakpointsMode}
            className="obt-hero-header-instance"
            containerClassName="obt-hero-header-2"
            headingClassName="obt-hero-header-5"
            overlapGroupClassName="obt-hero-header-3"
            underline="img/Underline.svg"
            underlineClassName="obt-hero-header-4"
          />
        </div>          
                
        <div className={`frame-tabs-container ${dataBreakpointsMode}`}>
          <div className={`frame-tabs frame-tabs-${dataBreakpointsMode}`}>        
          
            <ObtBibleButton />
          
            {dataBreakpointsMode === "desktop" && <div className={`heading-3 ${dataBreakpointsMode}`}>{BookHeading}</div>}
            
            <div className="horizontal-tabs">
            { !isOBTHelps && <>
              <StoryMode className="story-mode-toggle" />
              
              <div className="button">            
                <ObtViewButtons className="obt-view-buttons" />
              </div>
              </>
            }

            { isOBTHelps && 
                <ObtExpandAll className="obt-expand-all-button" clickActions={clickExpandCollapse} />
            }


          </div>
        


          </div>
        </div>
      

        {dataBreakpointsMode !== "desktop" && <div className={`heading-3 ${dataBreakpointsMode}`}>{BookHeading}</div>}

        {/* Akuo  */}
        { !isOBTHelps &&
          <Books className="books" />            
        }

        {/* OBTHelps  */}
        { isOBTHelps && (
          <div className={`resources ${VIEWSTATE_SMALL} `}>
            <div className="frame-resources">
              <ObtResources />
              <ObtResourcesExtras />
            </div>
          </div>
          )
        }

        {/* CONTACT SECTIONS */}
        {/* Only display for OBTHelps or SEB */}
        { (isOBTHelps || bibleId === "ENGSEB") &&
          <div className="contact-sections">
            <div className="content-wrapper">
              <div className="content-contact">
                {/* EMAIL SECTION */}
                <div className="contact-box">
                  <div className="contact-icon-wrapper">
                    <div className="featured-icon">
                      <PiChats className="obt-icon" fill="white"/>
                    </div>
                    <div className="contact-text">Email us</div>
                  </div>
                  <div className="contact-details">
                    <div className="text-and-supporting">
                      <div className="supporting-text">We appreciate your feedback on the Spoken English Bible.</div>
                    </div>
                    <ObtButton
                      className="obt-button-link"
                      textClassName="obt-button-link"
                      hierarchy="link-color"
                      iconLeading={false}
                      iconTrailing={false}
                      size="lg"
                      stateProp="default"
                      text={bibleDetails.seb_email}
                    />
                  </div>
                </div>
                <div className="contact-box">
                  <div className="contact-icon-wrapper">
                    <div className="featured-icon">
                      <PiChats className="obt-icon" fill="white"/>
                    </div>
                    <div className="contact-text">Technical Support</div>
                  </div>
                  <div className="contact-details">
                    <div className="text-and-supporting">
                      <div className="supporting-text">We are here to help!</div>
                    </div>
                    <ObtButton
                      className="obt-button-link"
                      textClassName="obt-button-link"
                      hierarchy="link-color"
                      iconLeading={false}
                      iconTrailing={false}
                      size="lg"
                      stateProp="default"
                      text={bibleDetails.obt_email}
                    />
                  </div>
                </div>
                
                {/* SUBSCRIBE SECTION */}
                {/* TBD: Remove "false" to enable -- Only OBT Helps can handle subscriptions */}
                { false && isOBTHelps && 
                  <div className="contact-box">
                    <div className="featured-icon">
                      <PiBellRinging className="obt-icon" fill="white"/>
                    </div>
                    <div className="contact-details">
                      <div className="text-and-supporting">
                        <div className="contact-text">Subscribe</div>
                        <p className="supporting-text">News about releases, tips, and articles about the latest in OBT</p>
                      </div>
                      <div className="email-capture">
                        <div className="obt-input-field">
                          <div className="input-with-label">
                            <div className="input">
                              <input className="content-5" placeholder="Enter your email" type="email" />
                            </div>
                          </div>
                          <p className="hint-text">
                            <span className="span">We care about your data in our </span>
                            <span className="text-wrapper-3">privacy policy</span>
                            <span className="span">.</span>
                          </p>
                        </div>
                        <ObtButton
                          className="obt-button-subscribe"
                          hierarchy="primary"
                          iconLeading={false}
                          iconTrailing={false}
                          size="md"
                          stateProp="default"
                          text="Subscribe"
                        />
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        }

        <div className={`obt-footer ${dataBreakpointsMode}`}>
          <div className={`container-footer ${dataBreakpointsMode}`}>
            <div className="play-content">
            <img className={`appstore-button style-${dataBreakpointsMode}`} style={{"maxHeight":"6rem"}} alt='Get it on Google Play'
                              src='/img/google-play-badge.png' onClick={() => {window.open(linkToPlayStore)}}/>
              { isOBTHelps &&
                <div className="heading-and-5">
                  {/* <p className={`heading-4 ${dataBreakpointsMode}`}>Let’s get started on something great</p> */}
                  <p className={`heading-5 ${dataBreakpointsMode}`}>Contact us to join the teams already working on Oral Bible Translation</p>
                </div>
              }

            </div>

            <ObtFooter  breakpoint={dataBreakpointsMode} />
          </div>

        </div>
        {/* <FabPlay className="fab-play-button" bookName={currentBook}/> */}

      </div>

      <div className="home-right-wrapper" >
        {dataBreakpointsMode === "desktop" && showPlayList &&
          <>
            {isOpenPlayer && 
              <Portal setIsOpen={setIsOpenPlayer} dimensions={dimensions}>
                <AudioPlaylist 
                  bookId={sourceId}
                  dataBreakpointsMode={dataBreakpointsMode}
                  />       
              </Portal>
            }
            
            {!isOpenPlayer && 
              <div className="right-frame">
                <AudioPlaylist 
                  bookId={sourceId}
                  dataBreakpointsMode={dataBreakpointsMode}
                  />   
              </div>
            }
          </>
        }

        {dataBreakpointsMode !== "desktop" && showPlayList &&
          <AudioPlaylist 
          bookId={sourceId}
          dataBreakpointsMode={dataBreakpointsMode}
          />   
        }
        
      </div>
      {downloaderComponentUI}      
    
    </div>

    <div>
      <br/>
      <br/>
      <br/>
    </div>

    </>
  );
};
