
import React from "react";
//import { AppData } from "../../components/AppContext";
import { HiQueueList as QueueOn, HiOutlineQueueList as QueueOff } from "react-icons/hi2";
import "./style.css";

export const ControlPlaylist = ( { className="", fillColor, showPlayList, toggleShowPlayList } ) => {  
  return (
    <>

    <div className={`control-queue-wrapper  ${className}`}>
      <button className={`queue ${className}`}
          type="button"
          aria-label="queue"
          onClick={toggleShowPlayList}
        >
          {showPlayList ? <QueueOn fill={fillColor}/> : <QueueOff stroke={"var(--gray-500)"}/>}
          <span className="tooltip">Playlist</span>
      </button>
    </div>
    </>
  );
};

