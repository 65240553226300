
import React from "react";
// import { AiTwotonePlayCircle } from "react-icons/ai";
import "./style.css";

export const ObtPlayCircle = ( { className, playAction } ) => {
  const obtPlayCircle = "/img/obt-play-circle-58.svg";
  return (
    <>
      {playAction &&
        <img className={`obt-play-circle ${className}`} alt="Obt play circle" src={obtPlayCircle} onClick={(e) => {playAction(e)}}/>
      }
      {!playAction &&
        <img className={`obt-play-circle ${className}`} alt="Obt play circle" src={obtPlayCircle} />
      }
    </>
  );
};

