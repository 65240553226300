import { OggOpusDecoderWebWorker } from 'ogg-opus-decoder';
import { OggVorbisDecoderWebWorker } from '@wasm-audio-decoders/ogg-vorbis';
import WavEncoder from "wav-encoder";

/**
 * 
 * @param {*} arrayBuffer 
 * @returns decoded array buffer for ogg opus or vorbis codecs
 */
export const decodeOgg = async (audioContext, arrayBuffer ) => {
  /**
   * Build a properly formatted audio buffer so it is consistent with other decoded formats
   * Ref: https://www.npmjs.com/package/ogg-opus-decoder for the decoded opus audio format
   *      https://www.npmjs.com/package/@wasm-audio-decoders/ogg-vorbis for vorbis - same format
   * 
   * @param {*} audioData 
   * @returns {*} audioBuffer
   */
  function formatAudioBuffer (audioData) {
    // console.log("formatAudioBuffer- audioData", audioData);
    const numberOfChannels = audioData.channelData.length;
    if (numberOfChannels > 0) {
      const audioBuffer = audioContext.createBuffer(
        numberOfChannels,
        audioData.samplesDecoded,
        audioData.sampleRate
      );

      // Copy the PCM data to the AudioBuffer
      for (let channel = 0; channel < numberOfChannels; channel++) {
        audioBuffer.copyToChannel(audioData.channelData[channel], channel);
      }
      // console.log("formatAudioBuffer - audioBuffer", audioBuffer);
      return audioBuffer;
    } else {
      return null;
    }
  }

  try {
     const arrayBuf = new Uint8Array(arrayBuffer);
     const decoderOpus = new OggOpusDecoderWebWorker({ forceStereo: true });
     try {
         await decoderOpus.ready   // wait for the decoder to initialize        
         console.log("Ogg Opus decoding....");
         return decoderOpus.decodeFile(arrayBuf)
         .then((audioData) => {
           // console.log("Ogg Opus decoding - audio data: " , audioData);
           return formatAudioBuffer(audioData);
         })
         .catch( (err) => {
           // If the error message complains about vorbis codec, decode using that decoder instead
           if (err.message.includes("vorbis")) {
             console.log('Ogg Vorbus decode required:', err.message);
             const decoderVorbis = new OggVorbisDecoderWebWorker({ forceStereo: true });
             return decoderVorbis.ready   // wait for the decoder to initialize
             .then(() => {
               console.log("Ogg Vorbis decoding....");
               return decoderVorbis.decodeFile(arrayBuf)
               .then((audioData) => {
                 return formatAudioBuffer(audioData);
               })
               .catch((err) => {
                 console.error('Ogg Vorbis decode audio error:', err);
                 return null;
               });
             })
             .catch((err) => {
               console.error('Ogg Vorbis decoder error:', err);
               return null;
             })
             .finally(() => {
               // console.log("Ogg Vorbis decoder freed for URI:", uri);
               decoderVorbis.free();
             });
           } else {
             console.error('Ogg Opus decode audio error:', err);
             return null;
           }
         })
       
     } catch(err) {
       console.error('Ogg Opus decoder error:', err);
       return null;
     }
     finally {
       // console.log("Ogg Opus decoder freed for URI:", uri);
       decoderOpus.free();
     }
   } catch(err) {
     console.error('decodeOgg error:', err, arrayBuffer);
     return null;
   }
 }

/**
   * Create playable URL for audio track
   * @param {blob} blob  representing file URL
   * @returns url - playable url
   */
export const createObjectURL = ( blob ) => {
  try {
    if ( window.webkitURL ) {
        return window.webkitURL.createObjectURL( blob );
    } else if ( window.URL && window.URL.createObjectURL ) {
      // For browsers that don't support webkit
        return window.URL.createObjectURL( blob );
    } else {
        return null;
    }
  } catch(err) {
      console.error("createObjectURL error", err);
      return null;
  }
}  

// TBD:  Consider making this a Hook to eliminate passing in the useState properties

/**
 * Merges audio files into a single blob if multiple passages
 * Before merging audio, decodes into a standard audioBuffer format to concatenate them.
 * 
 * @param {url} cardPassageAudio  A list of URLs for the audio passages to be decoded and merged
 * @param {integer} trackIndex - New track index in the playlist
 * @param {integer} cardSection - new section number in the playlist
 * @param {function} updateCurrentTrack - function to update the useState currentTrack URL 
 * @param {function} updateTrackIndex - function to update the useState trackIndex
 * @param {function} updateCurrentSection - function to update the useState currentSection value
 *        The above 3 functions are passed in because we can't access the useState directly in the App Context from this function
 * @returns {Promise} with Blob URL of the concatenated audio files in wav format
 */
export const setAudioPassage = async (cardPassageAudio,trackIndex,cardSection,updateCurrentTrack,updateTrackIndex,updateCurrentSection,setAudioLoading) => { 

  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  
  /**
   * Concatenate an array of audioBuffers into a single buffer
   * 
   * @param {audioBuffer} buffers 
   * @returns {audioBuffer}  Single buffer of concatenated audioBuffers
   */
  function concatBuffers(buffers) {
      const silenceDuration = 1; // Create silent buffer of 1 second
      var buflength = buffers.length;
      var channels = [];
      var sampleRates = [];
      var totalSamples = 0;
      var buf = null;
      var numberOfChannels;
      var newSampleRate;
      var badBufferCount = 0;
      var badBuffer = new Array(buflength);
      

      for(var i=0; i < buflength; i++){
        // console.log("Buffers - i ", i, buffers[i]);
        if (buffers[i] !== undefined && buffers[i] !== null) { 
          channels.push(buffers[i].numberOfChannels);// Store all number of channels to choose the lowest one after
          sampleRates.push(buffers[i].sampleRate);
          //totalDuration += buffers[i].duration;// Get the total duration of the new buffer when every buffer will be added/concatenated
          totalSamples += buffers[i].length;
          //console.log("totalSamples", totalSamples);  
        } else {
          badBuffer[i] = true;
          badBufferCount++;
        }
      }

      if (badBufferCount > 0) {
        console.log(`Warning: ${badBufferCount} audio buffers skipped due to missing or null value.`);
      }

      if (channels.length > 0) {
        numberOfChannels = channels.reduce(function(a, b) { return Math.min(a, b); });;// The lowest value contained in the array channels
        newSampleRate = sampleRates.reduce(function(a, b) { return Math.max(a, b); });;// The highest value contained in the buffers
        totalSamples += newSampleRate * silenceDuration * (buflength-1);  // increase by additional silence segments
        buf = audioContext.createBuffer(numberOfChannels, totalSamples, newSampleRate);// Create new buffer

        for (var b=0; b < numberOfChannels; b++) {
            var channel = buf.getChannelData(b);
            var dataIndex = 0;

            for(var c = 0; c < buflength; c++) {
              if (badBuffer[c] === undefined || badBuffer[c] !== true) {  // Skip any bad buffers
                channel.set(buffers[c].getChannelData(b), dataIndex);
                dataIndex += buffers[c].length; // Next position where we should store the next buffer values
                if (c < buflength - 1) {
                  // Insert silence between audio segments except after last segment
                  // This will give a slight pause between headings and chapters from the passage
                  dataIndex += newSampleRate * silenceDuration;
                }
              }
            }
        }
      }
      return buf;
  }  

  

  

  try {
    return new Promise( async  (resolve, reject) => {
    // cardPassageAudio contains a list of URLs for all scripture passages in the section
    // concatenate them into a single blob to play as one audio entity
    if (cardPassageAudio) {
      setAudioLoading(true);
      // Add a fake paraemter ?x-workaround to the URL to avoid caching issues
      let randomX = (Math.trunc(Math.random() * Math.pow(10, 5)) / Math.pow(10, 5)).toString();
      let proms = cardPassageAudio.map(uri =>
        fetch(uri + `?x-workaround=${randomX}`, {
          method: 'GET',
          mode: 'cors'
        })
        .then( (response) => {
          // console.log("response: " , response);
          if (response.status === 200) {
            return response.arrayBuffer()
              .then( (arrayBuffer)  => {
                console.log("Fetched ", uri);
                if (uri.endsWith('.ogg')) { 
                  // decode the ogg files 
                  return decodeOgg(audioContext, arrayBuffer)
                  .then((audioBuffer) => {
                    return audioBuffer;
                  })
                  .catch((err) => {
                    console.error('decode audio error:', err);
                  });

                } else {

                  // All other audio formats just convert to an audio buffer
                  console.log("Not ogg - decodeAudioData....");
                  return audioContext.decodeAudioData(arrayBuffer)
                  .then((audioBuffer) => {
                    return audioBuffer;
                  })
                  .catch((err) => {
                    console.error('decode audio error:', err);
                  });

                }
              });
          } else {
            console.error('Response status not OK:', response);
            return null;
          }
        })
        .catch((err) => {
          console.error('Fetch error:', err);
          return null; 
        })
      );
      
      // Wait for all the buffers to finish fetching and decoding (if oggs)
      Promise.all(proms).then((buffers) => {                
        let url='';
        // console.log("All buffers:", buffers);        
        if (buffers && buffers.length > 0 && buffers[0] !== undefined) {
          // concatenate all the channeldata
          let channelDataLeft = [];
          let channelDataRight = [];
          let samplesDecoded = 0;
          let sampleRate = 0;
          let newBuffer = concatBuffers(buffers)
          if (newBuffer !== undefined && newBuffer != null) {
            sampleRate = newBuffer.sampleRate;
            samplesDecoded = newBuffer.getChannelData(0).length
            channelDataLeft = newBuffer.getChannelData(0);
            if ( newBuffer && newBuffer.numberOfChannels > 1 ) {
              channelDataRight = newBuffer.getChannelData(0);
            } else {
              // Force stereo by making the two channels the same
              channelDataRight = channelDataLeft;
            }
            if (samplesDecoded > 0) { 
              const audioData = {
                sampleRate: sampleRate,
                channelData: [channelDataLeft, channelDataRight]
              };
              // Encode the buffer into a wav file (faster than mp3)
              return WavEncoder.encode( audioData )
              .then((buffer) => {
                  // console.log("WavEncoder buffer:", buffer);
                  let newBlob = new Blob([buffer], {type: "audio/wav"});
                  // console.log("setAudioPassage blob: ", newBlob);
                  url = createObjectURL(newBlob)                
                  if (url !== '') {
                    updateTrackIndex(trackIndex);
                    // console.log("setAudioPassage current track: ", url);
                    updateCurrentTrack(url);
                    updateCurrentSection(cardSection);
                  }
                  setAudioLoading(false);
                  resolve(url);   // resolve the promise
              })
              .catch((e) => {
                console.error("WavEncoder  error:", e);
                setAudioLoading(false);
                resolve(null);
              })
            } else {
              console.error("No samples decoded for URI:", url);
              setAudioLoading(false);
              resolve(null);
            }
          } else {
            console.error("Invalid audio stream");
            setAudioLoading(false);
            resolve(null);
          }
        } else {
          console.log("Promise - null buffer");
          setAudioLoading(false);
          resolve(null);
        }
      })
      .catch((e) => {
        console.error("setAudioPassage Promise error:", e);
        setAudioLoading(false);
      })
    
    }
});
  } catch (e) {
    console.error("setAudioPassage error: ", e);
    setAudioLoading(false);
  }
}