import { useState, useEffect } from "react";
import { BREAKPOINT_WIDTH_MOBILE, BREAKPOINT_WIDTH_TABLET } from '../constants.js';

const useWindowWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [dataBreakpointsMode, setDataBreakpointsMode] = useState("desktop");

  useEffect(() => {
    function handleResize() {
      let newWidth = window.innerWidth;
      let breakpoint = "";
      switch (true) {
        case newWidth <= BREAKPOINT_WIDTH_MOBILE:
          breakpoint = "mobile";
          break;
        case newWidth <= BREAKPOINT_WIDTH_TABLET:
          breakpoint = "tablet";
          break;
        default:
          breakpoint = "desktop";
          break;
      }
      //console.log("useWindowWidth", newWidth, breakpoint);
      setScreenWidth(newWidth);
      setDataBreakpointsMode(breakpoint); 
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    // Return is for unmount only:
    return () => window.removeEventListener("resize", handleResize);
  }, []);  

  return { screenWidth, dataBreakpointsMode };
}

export default useWindowWidth;