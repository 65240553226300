import PropTypes from "prop-types";
import React from "react";
import { SpeakerAnimate } from "../SpeakerAnimate";
import { TbTrashX as RemoveFromList } from "react-icons/tb";
import { AppData } from "../../components/AppContext";
import { PlayListData } from "../../components/AudioPlayListContext";
import { setAudioPassage } from "../../utils/setAudioPassage.js";
import { trackCardStart } from "../../utils/analytics.js";
import "./style.css";


export const AudioPlayListCard = ({
  cardShow=true,
  cardId,
  className,
  cardRef=null,
  cardAnimation="",  // used for placeholder shimmer on loading
  cardType,
  cardMode,
  cardBook,
  cardParent=null,
  cardSection,
  cardIndex = null,
  cardName,
  cardPassage,
  cardCategory=null,
  cardCategoryId=null,
  cardDuration = "-:--",
  cardImageClassName,
  cardImage,
  cardColor,
  cardAudioTitle = "",
  cardPassageAudio = [],
  cardState,    
  playAudioOnClick=false,
  setCurrentCardAction=null
  // setCurrentTrackAction=null    // ususally setAudioPassage
}) => {
    
  const { setCurrentTrack, setTrackIndex, setCurrentSection, setAudioLoading} = AppData();
  const { dispatch } = PlayListData();

  const clickActions = () => {
    const setCurrentCard = setCurrentCardAction?setCurrentCardAction():null;
    // const setTrackPassage = setCurrentTrackAction?setCurrentTrackAction():null;
    //console.log("clickActions: ");
    if (setCurrentCard) {
      //console.log(`AudioPlayListCard - setCurrentCard: ${cardIndex}`);
      (cardIndex !== null)?setCurrentCard(cardIndex):setCurrentCard(cardName);
    }
    if (playAudioOnClick ) {
      //console.log(`setTrackPassage: `, cardPassageAudio);
      if (cardPassageAudio) {
        trackCardStart("click", cardBook, cardMode, cardType, cardCategory, cardParent, cardSection, cardName);
        setAudioPassage(cardPassageAudio,cardIndex,cardSection,setCurrentTrack,setTrackIndex,setCurrentSection,setAudioLoading)        
      }      
    }    
  }

  const removeFromPlayList = (event) => {
    // console.log("removeFromPlayList - cardType:", cardType, "cardSection:", cardSection);
    
    if (cardType === "section" && cardSection !== undefined && cardSection !== null && cardSection !== "") {
      // console.log("removeFromPlayList - section:", cardSection);
      dispatch({ type: 'REMOVE_SECTION', payload: {section:cardSection}});
    } else {
      // console.log("removeFromPlayList - id", cardId);
      dispatch({ type: 'REMOVE_ID', payload: {id:cardId}});
    }
    event.stopPropagation();
  }

  let cardStyle = {};
  if (cardColor) {
    // Append opacity to the color - must use 2-digit hex "alpha" value to append to the #RRGGBB value: 75% = 0xBF; 80%=0xCC
    // The alpha value AA is a hex representation of 0-100 where 0 = 00 and 100 = FF.   round(AA / 100) * 255 - then convert to hex
    let color = cardColor + 'BF' 
    cardStyle = { borderColor: color, borderStyle: "solid" }
  }

  let hideStyle = cardShow?{}:{display:'none'};

  // Use a placeholder image unless this is an animation card
  const thisImage = !(cardAnimation||cardImage)?process.env.REACT_APP_PLACEHOLDER_IMAGE:cardImage;
  
  return (
    <div className={`play-list-card ${className} `} style={hideStyle} >      
      <div className="frame-card" onClick={clickActions} >          
          <RemoveFromList className='playlist-delete-icon' onClick={(e) => removeFromPlayList(e)}/>
          <div className={`frame-image ${cardAnimation}`} style={cardStyle} >
            {thisImage && <img className={`card-image ${cardImageClassName}`} src={thisImage} alt="audio card" />}          
          </div>
          <div className="frame-title">            
            <div className="card-text">
              <div className={`card-name ${cardAnimation}`}>{cardName}</div>
              <div className={`card-subtext ${cardAnimation}`}>{cardDuration}</div>                
            </div>
              
              <SpeakerAnimate className='title-icon' cardAudioTitle={cardAudioTitle} cardName={cardName}/>
            
          </div>
        </div>
    </div>
  );
};

AudioPlayListCard.propTypes = {
  cardType: PropTypes.oneOf(["section", "note"]),
  cardIndex: PropTypes.number,
  cardName: PropTypes.string,
  cardDuration: PropTypes.string,
  cardImage: PropTypes.string,    
  cardAudioTitle: PropTypes.string,
  cardState: PropTypes.oneOf(["large", "small"]),  
  navigateTo: PropTypes.string,
  cardRef: PropTypes.object
};