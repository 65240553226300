import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';
import { BOOK_TYPE_RESOURCES, BOOK_TYPE_EXTRAS } from '../constants.js';

export const GetAllCards = ( bibleId, bookId, resourceId, cardType, bookType ) => {
    // console.log('cardType:  ' + cardType);
    const isExtras = (bookType === BOOK_TYPE_EXTRAS);
    let cardTypePlural = (cardType === BOOK_TYPE_RESOURCES)?"movements":`${cardType}s`
    let sourceId = (cardType === BOOK_TYPE_RESOURCES) ? resourceId : bookId;
    let cards = [];
    let {isMVP, modeParm} = getModeParm();
    let thisParm = "?sections=false"
    if (modeParm !== "") {
      thisParm = `${modeParm}&sections=false`
    }
    let url = '';
    if (isExtras) {
      // Use "General" endpoint for extras
      url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/general/${sourceId}/${cardTypePlural}${thisParm}`
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/${sourceId}/${cardTypePlural}${thisParm}`
    }
    // console.log("GetAllCards url", url);
    const { data, isPending, isError, error } = useQuery({
        queryKey: [bibleId,bookType,sourceId,cardType],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        enabled: true
    }
  );  

  // if (!isPending && data && data.data[0] && data.data[0][cardTypePlural]) {    
  //   cards = data.data[0][cardTypePlural];    
  //   // console.log(cards);
  // }
  if (!isPending && data && data.data ) {
    if (isMVP ) {
      if (data.data[0] && data.data[0][cardTypePlural]) {
        cards = data.data[0][cardTypePlural];
      }
    } else {
      if (data.data[cardTypePlural]) {
        cards = data.data[cardTypePlural]      
        if (cards && cards.length > 1) {
          cards.sort((a, b) => a.movement - b.movement);
        }
      }
    }
  }
  
  return { sourceId, cards, isPending, isError, error }
}
