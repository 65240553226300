
import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ObtPlaceholder = ({ className, obtPlaceholder = "/img/obt-placeholder.png" }) => {
  return <img className={`obt-placeholder ${className}`} alt="Obt placeholder" src={obtPlaceholder} />;
};

ObtPlaceholder.propTypes = {
  obtPlaceholder: PropTypes.string,
};
