import React from "react";
import { ObtToggle } from "../../components/ObtToggle";
import { SpeakerAnimate } from "../SpeakerAnimate";
import "./style.css";

export const AudioNoteCategoryCard = ( {
  //className = "audio-note-category-card",
  cardIndex,
  cardAnimation=null,
  cardCategory = "",
  cardCategoryId,
  cardColor,  
  cardImage, 
  cardAudioTitle = "",
  cardToggle=true,
  toggleCategory
}) => {
  
  return (
    <div className="audio-category-card">
      <div className="frame-card">
        <div className="frame-background" style={{backgroundColor: `${cardColor}`}}>
          <div className={`frame-overlay ${cardAnimation}`}>
            <div className="image-frame" >
              <img className="image" alt="audio-note-card" src={cardImage} />
            </div>
            <div className="frame">
              <div className="card-name">{cardCategory}</div>
                <SpeakerAnimate className='title-icon' cardAudioTitle={cardAudioTitle} cardName={cardCategory}/>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-toggle">
        <ObtToggle
          className="obt-toggle-instance"
          pressed={cardToggle}
          size="md"
          state="default"
          color={cardColor}
          cardIndex={cardIndex}
          categoryId={cardCategoryId}
          toggleCategory={toggleCategory}
        />
      </div>
    </div>
  );
};
