import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';

export const GetAllChapters = ( bibleId, bookId, sectionsFlag=false ) => {
    let chapters = [];
    let {isMVP, modeParm} = getModeParm();
    let sectionsParm = `sections=${sectionsFlag}`;
    let thisParm = `?${sectionsParm}`;
    if (modeParm !== "") {
      thisParm = `${modeParm}&${sectionsParm}`
    }
    const url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/${bookId}/chapters${thisParm}`
    // console.log("GetAllChapters", url);
    const { data, isPending, isError, error } = useQuery({
        queryKey: [bibleId,bookId,"chapters"],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        enabled: true
    }
  );

  if (!isPending && data && data.data) {
    if (isMVP) {
      if (data.data[0] && data.data[0].chapters) {
        chapters = data.data[0].chapters;
      }
    } else {
      if (data.data.chapters) {
        chapters = data.data.chapters;
        if (chapters.length > 1) {
          chapters.sort((a, b) => a.chapter - b.chapter);
        }
      }
    }
  }
  // if (!isPending) console.log("GetAllChapters - chapters",chapters);
  let isPendingChapters = isPending;
  let isErrorChapters = isError;
  let errorChapters = error;
  return { bookId, chapters, isPendingChapters, isErrorChapters, errorChapters }
}
