
import React, {useRef }from "react";
import { ObtResourceLevel1 } from "../ObtResourceLevel1";
import { AppData } from "../../components/AppContext";
import { pushSection } from "../../utils/listFunctions.js";
import { downloadAudio } from "../../utils/downloadFunctions.js";
import { GetAllMovements } from "../../utils/GetAllMovements";
import { VIEWSTATE_SMALL, BOOK_TYPE_EXTRAS } from '../../constants.js';
import "./style.css";

const placeholderCards = [  
  {
    id: 1,
    movement: 1,
    title: "_"
  }
];

// This component applies only to OBT Helps
// It pulls all the movements for the selected parent book (as selected from a ResourceCard card)
// It displays a series of OBTResourceLevel1 cards representing each movement in the book
//
//    OBT Resource Hierarchy:
//    Home -> ResourcesExtras (get books) -> ResourceCard (Book UI) 
//            -> ResourceExtras2 (get movements) -> ResourceLevel1 (Movement UI) 
//                -> ResourceSections (get sections) -> ResourceLevel2 (Section UI)
// 
export const ObtResourceExtras2 = ({ 
  className, 
  viewState=VIEWSTATE_SMALL, 
  bookId, 
  parentImage
   }) => {

  const { currentBible, fileDownLoader, isOBTHelps } = AppData();
  const bibleId = currentBible['id'];
  const bookType = BOOK_TYPE_EXTRAS;
  const { movements, isPendingMovements, isErrorMovements, errorMovements } = GetAllMovements(bibleId, bookId, bookType, true);  // with sections

  /* List cards will track playable items and will be formatted as 
          {
              "key": unique key for the book
              "id": id for this type of card as returned by the API
              "index": index in the playlist,
              "section": section index in the playlist (may not be used)
              "sectionId": section id
              "title": title / name
              "passageAudio": list of audio files - filtered out chapter/title categories if disabled
              "passageAudioUnfiltered": list of all audio files regardless of category filters
              "passageFileName": list of audio file names - filtered out chapter/title categories if disabled
              "passageFileNameUnfiltered": list of all audio file names regardless of category filters
              "image": image file,
              "audioTitle": title audio,
              "passage": if note, this is the passage number
              "category": category name for notes,
              "categoryId": category id for notes,
              "color": color for note category,
              "duration": play duration
              "show":true/false to show this card - used to filter notes by categories
            }
  */
  const movementList = useRef([]);
  const passageList = useRef([]);
  const notesList = useRef([]);

  // Temp for testing loading ...
  // let isPendingMovements = true, movements = [], isErrorMovements = false, errorMovements = null; 
    
  // TBD: Add better error handling
  if (isErrorMovements) return 'Error fetching movements: ' + errorMovements.message

  // Count sections and tracks
  let cardTrackIndex = 0;  // for cardIndex value, which begins with track 1  

  /**
   * 
   * @param {*} movementCard 
   */
  const pushMovementCard = (thisKey,movementCard) => {
    let newCard = movementCard;
    newCard.key = thisKey;
    movementList.current.push(newCard);
    let parent = 'movements';
    if (movementCard.sections && movementCard.sections.length>0) {
      passageList.current[movementCard.id]=[];
      notesList.current[movementCard.id]=[];
      movementCard.sections.forEach(card => {
        cardTrackIndex++;
        pushSection(card,passageList.current[movementCard.id],notesList.current[movementCard.id],cardTrackIndex,bibleId,bookId,parent,movementCard.id,parentImage,isOBTHelps);
      });
    }
  }  

  /**
   * 
   * @param {*} key : unique key to identify the resource
   */
  const downloadResource = async (key) => {
    // console.log('downloading resource key ', key);
    let movementKey = null;
    // Lookup the passages for the desired key 
    //  - may be either a scripture section with multiple passages or a single note
    let filteredList = movementList.current.filter((card) => card.key === key); 
    // console.log("filteredList", filteredList);
    let movementCard=null;
    if (filteredList && filteredList.length>0) {
      movementCard = filteredList[0];
      movementKey = movementCard.id;
    }
    if (movementKey) {      
      // Lookup the passages for the desired key 
      //  - may be either a scripture section with multiple passages or a single note
      let filteredList1 = [];
      let filteredList2 = [];

      if (passageList.current[movementKey]) {
        filteredList1 = passageList.current[movementKey];
      }
      if (notesList.current[movementKey]) {
        filteredList2 = notesList.current[movementKey];
      }
      filteredList = filteredList1.concat(filteredList2);
      // console.log("filteredList concat:", filteredList)
      
      if (filteredList.length > 0) {
        let downloadName = "";
        if (movementCard.title) {
          downloadName = movementCard.title;
        } else {
          downloadName = "Movement-"+movementCard.movement;
        }
        
        downloadAudio('movement', fileDownLoader.current, filteredList, bookId, downloadName);
      }
    }
  } 

  return (
    <div className={`resource-movements ${viewState} ${className}`}>
      <div className="frame-resource-movements">

        {isPendingMovements && placeholderCards.map(card => {          
          return (
            <span className="skeleton-box" key={card.id}>
              <ObtResourceLevel1
                key={`${bookId}-Movement-${card.id}`}
                cardType="movements"
                cardKey={`${bookId}-Movement-${card.id}`}
                cardCode={card.id}
                cardName={card.title}
                cardAnimation = "shimmer"
                cardDuration={null}
                cardImageClassName={`card-image-${viewState}`}
                cardAudioTitle={null}
                showPlay={false}
                showDownload={false}
                bookType={bookType}
              />
            </span>
          )
        })}

        {!isPendingMovements && movements && (
          <>
            {(() =>{
              movementList.current = [];
              
              return movements.map(card => {
                let thisKey = `${bookId}-Movement-${card.id}`
                // console.log("Movement key: " + thisKey)
                pushMovementCard(thisKey,card);
                return (
                  <ObtResourceLevel1
                    key={`${bookId}-Movement-${card.id}`}
                    className="resource-link-instance"
                    bookType={bookType}
                    cardBookId={bookId}
                    cardType="movements"
                    cardCode={card.id} 
                    cardName={card.title}
                    cardDuration={null}  //TBD
                    cardImageClassName={`card-image-${viewState}`}
                    cardImage={card.images[0]?card.images[0].url?card.images[0].url:parentImage:parentImage}
                    cardAudioTitle={card.title_audio?card.title_audio[0]?.url?card.title_audio[0]?.url:null:null}
                    showPlay={false}
                    showDownload={true}
                    downloadAction={()=>{downloadResource(card.key)}}
                  />
                )
              });
            })()}
          </>
        )}
      </div>      
    </div>
  );
};

