import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';

export const GetAllResources = (resourceId) => {
  let {modeParm} = getModeParm();
    const url = `${process.env.REACT_APP_BASE_URL}/bibles/${resourceId}/books${modeParm}`;
    // console.log("GetAllResources", url);
    let resources = [];    
       
    const { data, isPending, isError, error } = useQuery({
        queryKey: [resourceId,'resources'],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        enabled: true
    });  

    // if (!isPending && data) {
    //   resources = data.data;        
    // }
    if (!isPending && data) {      
      resources =  data.data;
      if (resources.length > 1) {
        resources.sort((a, b) => (a.book_order?a.book_order:0 > b.book_order?b.book_order:1)?1:-1);
      }
    }

  return { resources, isPending, isError, error }
}
