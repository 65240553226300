
import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ObtLogo = ({
  className,
  logoStyle,
  navHome=false  
}) => {
  const logo = {"desktop":process.env.REACT_APP_LOGO_DESKTOP,
                "tablet":process.env.REACT_APP_LOGO_TABLET,
                "mobile":process.env.REACT_APP_LOGO_MOBILE,
              };
  
  const navigateHome = () => {
    if (navHome) {
      window.location.href = "/";
    }
  }

  const navClass = navHome?"obt-logo-nav":"";

  return (
    <img
      className={`obt-logo style-${logoStyle} ${className} ${navClass}`}
      alt="Style" onClick={navigateHome}
      src={logo[logoStyle]}
    />
  );
};

ObtLogo.propTypes = {
  logoStyle: PropTypes.oneOf(["desktop", "tablet", "mobile"]),
};
