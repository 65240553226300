import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { ObtPlayCircle } from "../ObtPlayCircle";
import "./style.css";

export const ObtQuickPlay = ({ stateProp, className, duration, playAction }) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "default",
  });

  return (
    <div
      className={`obt-quick-play ${state.state} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className="frame">      
        {state.state === "hover" && (
          <div className="overlap-group">                    
              <div className="text-wrapper">{duration}</div>
          </div>        
        )}
        {playAction &&
          <ObtPlayCircle className="obt-play-circle" playAction={playAction}/>
        }
        {!playAction && <ObtPlayCircle className="obt-play-circle" />}
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };

    default:
  }

  return state;
}

ObtQuickPlay.propTypes = {
  stateProp: PropTypes.oneOf(["hover", "default"]),
};
