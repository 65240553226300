
import PropTypes from "prop-types";
import { RxHamburgerMenu } from "react-icons/rx";
import "./style.css";

export const MobileMenu = ({ className, breakpoint, onClick=null }) => {

  return (
    <div className={`mobile-menu ${className}`} >
      <RxHamburgerMenu className={'mobile-menu-burger'} onClick={onClick}/>
    </div>
  )
};

MobileMenu.propTypes = {
  breakpoint: PropTypes.oneOf(["desktop", "tablet", "mobile"])
};
