import React from "react";
import { AppData } from "../../components/AppContext";
import useWindowWidth from "../../hooks/useWindowWidth";
import { STORY_MODE, TRADITIONAL_MODE } from '../../constants.js';
import "./style.css";

export const StoryMode = () => {
  const { storyMode, toggleMode } = AppData();
  const { dataBreakpointsMode } = useWindowWidth();
  return (
    <label className={`story-mode ${storyMode} ${dataBreakpointsMode}`}>
      <input type="checkbox" checked={storyMode===STORY_MODE} onChange={toggleMode}/>    
      <span className="ellipse"/>
      <div className="text-frame">{storyMode === TRADITIONAL_MODE && <div className="text">Traditional</div>}</div>
      <div className="text-frame">{storyMode === STORY_MODE && <div className="text">Story-based</div>}</div>      
    </label>    
  );
};

