import React from "react";
import { AppData } from "../../components/AppContext";
import "./style.css";

export const BibleInfo = () => {
  const { currentBible } = AppData();
  const bibleName = currentBible['name'];
  const isSEB = currentBible['id']?currentBible['id'].substring(0,6) === "ENGSEB"?true:false:false;
  // TBD:  Replace this with BIble info from API
  // Need to call endpoint /bibles/:bible_id
  // Pull details from "description" and copyright info from "mark"
  // Temporarily include details for ENGSEB but leave blank for other bibles until the endpoint provides the information
  let bible = {mark:"Copyright information not available.", description:""};
  if (isSEB) {
    bible.description = "The Spoken English Bible® (SEB®) is a new translation of the Bible that is intended to be heard and told, not read. " + 
"It is being produced from the original languages and is intended to maximize retention while still being faithful to the meaning. " +
"Listen to our podcast series for more information about this translation and to stay up to date with future developments. " +
"The SEB® is owned and produced by SIL International. SIL International is a global, faith-based nonprofit that works with local communities around the world to develop language solutions that expand possibilities for a better life." 
            
  } 
  return (    
    <div className="Bible-info">      
      <div className="content">
        <div className="nav-menu-item">
          {/* <img className="akuo-icon" alt="Akuo icon" src="/img/akuo-logo.png" /> */}
          <div className="title">{bibleName}</div>
          <div className="description">
            <div className="flexcontainer">
              <span className="span-wrapper">
                <>{bible.description} </>              
              </span>
            </div>
          </div>
          <div className="copyright">
            {isSEB && <>
            <p className="copyright-text">
              <span className="span">The Spoken English Bible® (SEB®) is licensed under a </span>
                <a href="https://creativecommons.org/licenses/by-sa/4.0/" rel="noopener noreferrer" target="_blank">
                  <span className="span">Creative Commons Attribution-ShareAlike 4.0 International License</span>
                </a>
              <span className="span">.</span>
            </p>
            <img className="copyright-image" alt="Copyright" src="/img/creative-commons-image.png" />
            </>
            }
            {!isSEB && 
              <p className="copyright-text">
                {bible.mark}
              </p>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
