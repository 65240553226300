import React from "react";
import { useEffect, useState, useRef } from "react";
import { AppData } from "../../components/AppContext";
import { playTitle } from "../../utils/playTitle.js";
import "./style.css";

// Define the three levels of speaker SVG: low, medium, high - to be used in animation.
const SVG1 = () => (
<svg viewBox="0 0 23 23" width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.4062 16.0625L7.125 13.3125H4.5V9.1875H7.125L10.4062 6.4375V16.0625Z" stroke="black" strokeOpacity="0.75" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
  <g id="wave-group">
    <path id="wave1" d="M 11.504 12.449 C 11.783 12.135 11.922 11.744 11.922 11.392 C 11.922 11.039 11.783 10.647 11.504 10.333" stroke="black" strokeOpacity="0.75" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />    
  </g>
</svg>

);

// SVG2.jsx
const SVG2 = () => (
<svg viewBox="0 0 23 23" width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.4062 15.0625L7.125 12.3125H4.5V8.1875H7.125L10.4062 5.4375V15.0625Z" stroke="black" strokeOpacity="0.75" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
  <g id="wave-group">
    <path id="wave2" d="M 13.213 13.083 C 13.825 12.267 14.13 11.252 14.13 10.337 C 14.13 9.419 13.825 8.4 13.213 7.584" stroke="black" strokeOpacity="0.75" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
  </g>
</svg>
);

// SVG3.jsx
const SVG3 = () => (
<svg viewBox="0 0 23 23" width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.4062 15.0625L7.125 12.3125H4.5V8.1875H7.125L10.4062 5.4375V15.0625Z" stroke="black" strokeOpacity="0.75" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>
  <g id="wave-group">
    <path id="wave3" d="M 15.226 14.943 C 16.208 13.543 16.697 11.801 16.697 10.231 C 16.697 8.656 16.208 6.907 15.226 5.507" stroke="black" strokeOpacity="0.75" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
  </g>
</svg>

);

export const SpeakerAnimate = ( {className, cardAudioTitle, cardName} ) => {
  const { isAppleDevice } = AppData();
  const [animateSVG, setAnimateSVG] = useState(false);
  const [currentSVG, setCurrentSVG] = useState(1);  // start with middle speaker
  const svgComponents = [<SVG1 />, <SVG2 />, <SVG3 />];
  
  // Ref for useEffect  
  const intervalRef = useRef();

  useEffect(() => {    
    if (animateSVG) {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        setCurrentSVG((prevSVG) => (prevSVG + 1) % svgComponents.length);
      }, 100); // Change SVG every 100 milliseconds to animate
    }    
    return () => {setCurrentSVG(1); clearInterval(intervalRef.current)};
  }, [svgComponents.length, animateSVG]);

  const PlayTitle = async (event) => {
    playTitle(isAppleDevice, cardAudioTitle, setAnimateSVG, cardName)
    event.stopPropagation();
  }
    
  return (
    <div className={`${className} svg-container`}>
      {svgComponents.map((svg, index) => {
        // Alternate between the different speaker SVGs to animate
        let isCurrent = index === currentSVG ? 'active' : '';
        if (index === currentSVG) {
          return( 
            <div key={index} className={`wave-group ${isCurrent}`} onClick={(e) => {PlayTitle(e)}}>
              {svg}
            </div>
          )
        } else {
          return null;
        }
      })}
    </div>
  );
}
