import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';

export const GetBibles = ( iso ) => {
    let bibles = [];
    let {modeParm} = getModeParm();
    // console.log("iso", iso);
    // const language = iso;
    const divider = modeParm === "" ? "?" : "&";
    const languageParm=iso?`${divider}language_code=${iso}`:"";
    const thisURL = `${process.env.REACT_APP_BASE_URL}/bibles${modeParm}${languageParm}`;
    // console.log("GetBibles: " + thisURL);
    const { data, isPending, isError, error } = useQuery({
        queryKey: ['bibles',iso],
        queryFn: () => axiosClient.get(thisURL).then((res) => res.data),
        enabled: true
    });  

    if (!isPending && data) {      
      bibles =  data.data;
      if (bibles.length > 1) {
        bibles.sort((a, b) => (a.name?.toUpperCase() > b.name?.toUpperCase())?1:-1);
      }
    }
     

  return { bibles, isPending, isError, error }
}
