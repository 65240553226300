
import React from "react";
import { TbFilterEdit as FilterIcon } from "react-icons/tb";
import { CategoryModal } from "../../components/CategoryModal";
import { getLocalCategories } from "../../utils/categoryFunctions.js";
import { PlayListData } from "../../components/AudioPlayListContext";
import { AppData } from "../../components/AppContext";
import "./style.css";

export const CategoryFilter = ( { className="", toggleCategory } ) => {
  const {isOpenCategory, setIsOpenCategory, currentBible} = AppData();
  const { dispatch } = PlayListData();

  function closeCategoryModal() {
    // Refresh the playList before closing
    const bibleId = currentBible['id'];
    let thisCategoryList = getLocalCategories(bibleId);
    dispatch({ type: 'REFRESH', payload: {categoryList:thisCategoryList}});
    //Close the dialog 
    setIsOpenCategory(false);
    // Revert to scrollable page when modal closes
    document.body.style.overflow = "auto";
  }

  function toggleCategoryModal() {
    if (isOpenCategory) {
      closeCategoryModal()
    } else {
      setIsOpenCategory(true)
    }    
  }
  return (
    <>
    <div className={`category-modal-wrapper ${className}`}>
        <button className={`category-filter ${className}`} onClick={() => {toggleCategoryModal()}}>
          <FilterIcon />
          <span className="tooltip">Categories</span>
        </button>
        
        {isOpenCategory && 
          <CategoryModal 
              className="category-modal" 
              toggleCategory={toggleCategory} 
              open={true} 
              onClose={() => {closeCategoryModal()}}/>          
        }
    </div>    
    </>
  );
};

CategoryFilter.propTypes = {
  // state: PropTypes.oneOf([VIEWSTATE_LARGE, VIEWSTATE_SMALL]),
};
