
import React from "react";
import { ElementCard } from "../ElementCard";
import { AppData } from "../../components/AppContext";
import { GetAllResources } from "../../utils/GetAllResources";
import { BOOK_TYPE_RESOURCES } from '../../constants.js';
import "./style.css";

const placeholderCards = [  
  {
    book_id: "TMP1",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP2",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP3",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP4",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP5",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP6",
    name: "_",
    name_alt: "_"
  }
];

export const Resources = ({ className }) => {

  const { storyMode, viewState, currentOBTResource, setCurrentResource } = AppData();
  const resourceId = currentOBTResource['id'];
  const { resources, isPending, isError, error } = GetAllResources(resourceId);

  // TBD: Add better error handling
  if (isError) return 'An error has occurred: ' + error.message  

  return (
    <div className={`obtresources ${storyMode} ${viewState} ${className}`}>
      <div className="frame-resources">

        {isPending && placeholderCards.map(resource => {          
          return (
            <span className="skeleton-box" key={resource.book_id}>
            <ElementCard
              key={resource.book_id}
              cardCode={resource.book_id}
              cardType={BOOK_TYPE_RESOURCES}
              cardAnimation = "shimmer"
              cardName={resource.name}
              cardDuration="_"
              cardImageClassName={`card-image-${viewState}`}
              //cardImage
              cardAudioTitle={null}
              cardState={viewState}
              subheadingClassName="element-card-subheading"                                 
              showPlay={false}                   
              showDownload={false}                         
              overlapGroupClassName="instance-node"
            />
            </span>
          )
        })}    
        

        {!isPending && resources && resources.map(resource => {     
            return (
            <ElementCard
              key={resource.book_id}
              cardCode={resource.book_id}
              cardType={BOOK_TYPE_RESOURCES}
              cardName={resource.name}
              cardDuration={resource.duration}
              cardImageClassName={`card-image-${viewState}`}
              cardImage={resource.images[0]?resource.images[0].url?resource.images[0].url:null:null}
              cardAudioTitle={resource.title_audio?resource.title_audio[0]?.url?resource.title_audio[0].url:null:null}
              cardState={viewState}
              subheadingClassName="element-card-subheading"                                 
              showPlay={false}     
              //obtQuickPlayClassName={`card-quickplay-${viewState}`}
              showDownload={false}           
              obtDownloadClassName={`card-download-${viewState}`}
              overlapGroupClassName="instance-node"
              navigateTo="/sections"              
              setCurrentCardAction={() => setCurrentResource}
            />
            )
          })}
      </div>      
    </div>
  );
};

