import React from "react";
import { useNavigate } from 'react-router-dom';
import { ObtNavMenuItem } from "../ObtNavMenuItem";
import { AppData } from "../../components/AppContext";
import { localSettings } from "../../utils/localSettings.js";
import { GetBibles } from "../../utils/GetBibles";
import "./style.css";

const placeholderCards = [  
  {
    bible_id: "TMP1",
    name: "_"
  },
  {
    bible_id: "TMP2",
    name: "_"
  }
];

export const BiblesMenu = ( {onLeave=null, setMobileOpen=null} ) => {
  const { currentLanguage, setCurrentBible } = AppData();  
  const { bibles, isPending, isError, error } = GetBibles(currentLanguage['iso']);
  const navigate = useNavigate();

  // TBD: Add better error handling
  if (isError) return 'An error has occurred: ' + error.message

  const selectBible = ( bible ) => {
    console.log('setBIble:', bible); 
    if (bible !== undefined && bible !== null) {
      if (bible.bible_id !== undefined && bible.bible_id !== null && bible.bible_id !== "") {
        let thisBible = {'id':bible.bible_id, 'name':bible.name};
        setCurrentBible(thisBible);
        localSettings('setItem', 'bible', thisBible);
      }
    }
    if (setMobileOpen !== undefined && setMobileOpen !== null) {setMobileOpen(false);}
    navigate('/home');
    onLeave();
  }

  return (
    <div className="bibles-menu dropdown-menu" >
      <div className="content-2"> 

        {isPending && placeholderCards.map(bible => {          
          return (
            <span className="skeleton-box" key={bible.bible_id}>
                  <ObtNavMenuItem      
                    key={bible.bible_id}
                    breakpoint="desktop"
                    className="bibles-menu"
                    // icon="/img/akuo-logo.png"
                    stateProp="default"
                    text={bible.name}
                    // subtext={language.autonym}
                  />             
            </span>
          )
        })}

        {!isPending && bibles && bibles.map(bible => {
          // console.log("language:",language);
          return (
            <ObtNavMenuItem
              key={bible.bible_id}
              id={bible.bible_id}
              breakpoint="desktop"
              className="bibles-menu"
              // icon="/img/akuo-logo.png"
              stateProp="default"
              text={bible.name}
              // subtext="A new translation of the Bible that is intended to be heard and told, not read. It is being produced from the original languages and is intended to maximize retention while still being faithful to the meaning."
              subtext=""
              onClick={() => selectBible(bible)}
            />
          )
        })}
      </div>
    </div>
  );
};
