
import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Link } from "react-router-dom";
import useWindowWidth from "../../hooks/useWindowWidth";
import { BiblesMenu } from "../BiblesMenu";
import { Languages } from "../Languages";
import "./style.css";

export const ObtMenuItem = ({ name, stateProp, className, textClassName="menu-text-default", setMobileOpen}) => {
  const { dataBreakpointsMode } = useWindowWidth();
  const [state, dispatch] = useReducer(reducer, {
    name: name || "home",
    state: stateProp || "default",
  });

  return (
    <div
      className={`obt-menu-item state-1-${state.state} ${state.name} ${className} ${dataBreakpointsMode}`}
      to="/home"
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className={`obt-menu-text ${textClassName}`}>
        {state.name === "home" && 
          <Link to="/home">Home</Link>}

        {state.name === "languages" && 
          <>
            <Link to="#">Languages</Link>            
            <Languages className="menu-block" onLeave={() => dispatch("mouse_leave")} setMobileOpen={setMobileOpen}></Languages>
          </>
        }

        {state.name === "bibles" && 
          <>
            <Link to="#">Bibles</Link>
              <BiblesMenu className="menu-block" onLeave={() => dispatch("mouse_leave")} setMobileOpen={setMobileOpen}></BiblesMenu>
          </>
        }
        
        {state.name === "resources" && 
          <Link to="/resources">Resources</Link>
        }

        {state.name === "extras" && 
          <Link to="/extras">Extras</Link>
        }

        {state.name === "export" && <>Export</>}

        <div>
          {state.state === "hover" && 
            <img
              className="line"
              alt="Line"
              src="/img/line-1-1.png"
            /> 
          }
        </div>

      </div>      
    </div>    
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };

    default:
  }

  return state;
}

ObtMenuItem.propTypes = {
  name: PropTypes.oneOf(["export", "bibles", "resources", "languages", "home", "extras"]),
  stateProp: PropTypes.oneOf(["hover", "default"]),
};
