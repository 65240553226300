
import { decodeOgg, createObjectURL } from "./setAudioPassage.js";
import WavEncoder from "wav-encoder";

export const playTitle = async (isAppleDevice, audioTitle, setAnimateSVG, cardName) => {  
    try {
      console.log("playTitle isAppleDevice: " , isAppleDevice);
      console.log("playTitle cardName:",cardName, audioTitle);
      let url = audioTitle;
      if (url && url !== "") {
        // TBD::  TEMPORARY WORKAOUND - fix the prefix
        url = (url.indexOf('://') === -1) ? 'https://' + url : url;
        console.log("playTitle url", url);
        // Validate the URL and only play if response is OK
        await fetch(url , {
                  method: 'GET',
                  mode: 'cors'
                    })
          .then(async response => {
            // console.log("response", response);
            if (response.ok) {

              let newBuffer=null;
              if (isAppleDevice && url.endsWith('.ogg')) { 
                try {
                  let arrayBuffer = await response.arrayBuffer()
                  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                  // decode the ogg files 
                  newBuffer = await decodeOgg(audioContext, arrayBuffer)
                  // console.log("newBuffer", newBuffer);
                }
                catch(err) {
                    console.error('decode audio error:', err);
                }

                if (newBuffer !== undefined && newBuffer != null) {
                  // console.log("newBuffer: ", newBuffer)
                  let channelDataLeft = [];
                  let channelDataRight = [];
                  let samplesDecoded = 0;
                  let sampleRate = 0;
                  sampleRate = newBuffer.sampleRate;
                  samplesDecoded = newBuffer.getChannelData(0).length
                  channelDataLeft = newBuffer.getChannelData(0);
                  if ( newBuffer && newBuffer.numberOfChannels > 1 ) {
                    channelDataRight = newBuffer.getChannelData(0);
                  } else {
                    // Force stereo by making the two channels the same
                    channelDataRight = channelDataLeft;
                  }                  
                  if (samplesDecoded > 0) { 
                    const audioData = {
                      sampleRate: sampleRate,
                      channelData: [channelDataLeft, channelDataRight]
                    };
                    // Encode the buffer into a wav file (faster than mp3)
                    try {
                      let buffer = await WavEncoder.encode( audioData )
                      let newBlob = new Blob([buffer], {type: "audio/wav"});
                      url = createObjectURL(newBlob)
                    } catch(e) {
                        console.error("WavEncoder  error:", e);                        
                    }
                  }
                }
              } 
              
              let audioFile = url ? new Audio( url ) : null;              
              if (audioFile) {
                // console.log("audioFile: ", audioFile)
                audioFile.addEventListener('loadedmetadata', function() {
                  if (setAnimateSVG ) {
                    setAnimateSVG(true);
                  }
                });

                audioFile.addEventListener('ended', function() {
                  if (setAnimateSVG) setAnimateSVG(false);
                });

                audioFile.play()
              }
              
            }
          })
          .catch(e => {
            console.error("PlayTitle error: ", e);
          });
      }
    } catch (e) {
      console.error("PlayTitle error: ", e);
    }    
  }