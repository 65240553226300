
import React from "react";
import { ObtNavMenuItem } from "../ObtNavMenuItem";
import { AppData } from "../../components/AppContext";
import { localSettings } from "../../utils/localSettings.js";
import { GetLanguages } from "../../utils/GetLanguages";
import "./style.css";

const placeholderCards = [  
  {
    id: "TMP1",
    name: "_"
  },
  {
    id: "TMP2",
    name: "_"
  }
];

export const Languages = ({ className, onLeave=null, setMobileOpen}) => {
  
  const { setCurrentLanguage } = AppData();  
  const { languages, isPending, isError, error } = GetLanguages();

  // TBD: Add better error handling
  if (isError) return 'An error has occurred: ' + error.message

  const selectLanguage = ( language ) => {
    // Expects language parm to be in the format {iso:string, name:string}
    //console.log("setLanguage", language); 
    if (language !== undefined && language !== null) {
      let iso = language?language.iso:null;
      if (iso !== undefined && iso !== null && iso !== "") {
        setCurrentLanguage(language);
        localSettings('setItem', 'language', language);
      }
    }
    if (setMobileOpen !== undefined && setMobileOpen !== null) {setMobileOpen(false);}
    onLeave();
  }

  return (
      <div className="languages-menu dropdown-menu" >
        <div className="content-2"  >

          {isPending && placeholderCards.map(language => {          
            return (
              <span className="skeleton-box" key={language.id}>
                    <ObtNavMenuItem      
                      key={language.id}
                      breakpoint="desktop"
                      className="languages-menu"
                      // icon=""
                      stateProp="default"
                      text={language.name}
                      // subtext={language.autonym}
                    />             
              </span>
            )
          })}

          {!isPending && languages && languages.map(language => {
              // console.log("language:",language);
              // TEMPORARY WORKAROUND FOR DEV BUG with "en"
              const thisLanguage = language.name==='en'?"English":language.name;
              return ( 
                <ObtNavMenuItem      
                  key={language.id}
                  breakpoint="desktop"
                  className="languages-menu"
                  // icon=""
                  stateProp="default"
                  text={thisLanguage}
                  subtext={null}
                  // subtext={language.autonym}
                  onClick={() => selectLanguage({iso:language.iso, name:thisLanguage})}
                  />
              )
            })}
        </div>      
      </div>
  );
};


