import React from "react";
import { RiPlayListAddLine as PlayListAddIcon} from "react-icons/ri";
import { PlayListData } from "../AudioPlayListContext";
import { addToPlayList } from "../../utils/playlistFunctions.js";
import "./style.css";

export const ObtPlayListAdd = ( {
    className, 
    cardId=null,
    cardType=null,
    cardMode=null,
    cardBook=null,
    cardParent=null,
    cardSection=null,
    cardName=null,
    cardPassageAudio=null,
    cardPassageFileName=null,
    cardPassage=null,
    cardCategory=null,
    cardCategoryId=null,
    cardDuration=null,
    cardImage=null,
    cardAudioTitle=null,
    cardColor=null,
    showCard=null,
    overrideDelete=false,
    cardNotes=null   // if included then add the notes related to the selected passage card
    } ) => {

  const { dispatch } = PlayListData();

  const clickActions = (event) => {

    addToPlayList(
      {
        cardId:cardId,
        cardType:cardType,
        cardMode:cardMode,
        cardBook:cardBook,
        cardParent:cardParent,
        cardSection:cardSection,
        cardName:cardName,
        cardPassageAudio:cardPassageAudio,
        cardPassageFileName:cardPassageFileName,
        cardPassage:cardPassage,
        cardCategory:cardCategory,
        cardCategoryId:cardCategoryId,
        cardDuration:cardDuration,
        cardImage:cardImage,
        cardAudioTitle:cardAudioTitle,
        cardColor:cardColor,
        showCard:true,
        overrideDelete:overrideDelete,
        cardNotes:cardNotes,
        dispatch:dispatch
      }
    );

    event.stopPropagation();
  }
  
  return (
    <div className={`obt-playlist-add ${className}`}>
      <div className="obt-playlist-add-frame" >
          <div className="icon-frame">
            <span className="tooltip">Add to Playlist</span>
            <PlayListAddIcon className="obt-playlist-add-icon" onClick={(e)=>clickActions(e)}/>
          </div>
      </div>
    </div>
  );
}
