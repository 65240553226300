
import React from "react";
import { Link } from "react-router-dom";
import { AppData } from "../../components/AppContext";
import { STORY_MODE, BOOK_TYPE_RESOURCES, BOOK_TYPE_EXTRAS } from '../../constants.js';
import { RxChevronRight } from "react-icons/rx";
import "./style.css";

export const Breadcrumbs = ( { page } ) => {
    
    const { storyMode, currentBookName, currentBookNames, navigateFrom, currentResourceName, bookType } = AppData();  
    const linkToResource = (navigateFrom === BOOK_TYPE_RESOURCES);
    const linkToExtras = (bookType === BOOK_TYPE_EXTRAS);
    const isStoryMode = (storyMode === STORY_MODE);
    const linkToMovement = (page === "sections" && !linkToResource)? 
            ( (linkToExtras || isStoryMode ) ? true : false) : false;
    const linkToChapter = (page === "sections" && !linkToResource)? 
            ( (linkToExtras || isStoryMode ) ? false : true) : false;    
    const thisBookName = currentBookNames?currentBookNames[storyMode]:currentBookName;

    return (
    <div className="breadcrumbs">
        <div className="crumb home" key="home">
            {/* Spaces inserted to allow for the home background icon added via css */}
            <Link to="/">&nbsp;&nbsp;&nbsp;&nbsp;</Link>               
        </div>
        <div className="crumb">
        <RxChevronRight className="breadcrumb-right"/>
        </div>
        
        {(linkToExtras) && (
            // First portion of the path goes back to Extras page
            <>
            <div className="crumb crumb-label">
                <Link to="/extras">Extras</Link>
                <RxChevronRight className="breadcrumb-right"/>
            </div>
            </>
        )}


        {(linkToMovement) && (
            // First portion of the path is the bookname
            <>
            <div className="crumb crumb-label">
                <Link to="/movements">{thisBookName}</Link>
                <RxChevronRight className="breadcrumb-right"/>
            </div>
            </>
        )}

        {/* {(page === "chapters" || linkToChapter) && ( */}
        {(linkToChapter) && (
            // First portion of the path is the bookname
            <>
            <div className="crumb crumb-label">
                <Link to="/chapters">{thisBookName}</Link>
                <RxChevronRight className="breadcrumb-right"/>
            </div>
            </>
        )}

        {(linkToResource) && (
            // First portion of the path is the bookname
            <>
            <div className="crumb crumb-label">
                <Link to="/resources">{currentResourceName}</Link>
                <RxChevronRight className="breadcrumb-right"/>
            </div>
            </>
        )}

    </div>
    )
}