import React from "react";
import { RxDownload } from "react-icons/rx";
import "./style.css";

export const ObtDownload = ( { className, onClick } ) => {
  return (
    <div className={`obt-download ${className}`} >
      <RxDownload className={className} fill="rgb(235 241 249 / 50%)" onClick={onClick}/>
    </div>
  );
};
