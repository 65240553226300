import { segmentAnalytics } from "../components/AppContext";
import { getBibleId } from "./localSettings.js";

export const trackPage = (pageName, mode=null, book=null, parent=null, parentName=null) => {
  segmentAnalytics.page(pageName, {
    bible:getBibleId(),
    book:book,
    mode:mode,    
    parent:parent,
    parentName:parentName
  });
}

export const trackCardStart = (action, book, mode, type, category, parent, section, name) => {
  // console.log("trackCardStart", {
  //   action: action,
  //   bible:getBibleId(),
  //   book:book,
  //   mode:mode,
  //   type:type,
  //   category:category,
  //   parent:parent,    
  //   section:section,
  //   name:name});

  segmentAnalytics.track("Play Start", {
    action: action,
    bible:getBibleId(),
    book:book,
    mode:mode,
    type:type,
    category:category,
    parent:parent,    
    section:section,
    name:name
  })
}

export const trackCardEnd = (action, book, mode, type, category, parent, section, name, duration) => {
  // console.log("trackCardEnd", {
  //   action: action,
  //   bible:getBibleId(),
  //   book:book,
  //   mode:mode,
  //   type:type,
  //   category:category,
  //   parent:parent,    
  //   section:section,
  //   name:name});

  segmentAnalytics.track("Play End", {
    action: action,
    bible:getBibleId(),
    book:book,
    mode:mode,
    type:type,
    category:category,
    parent:parent,    
    section:section,
    name:name,
    duration: duration
  })
}