import React, { useEffect } from "react";
import useWindowWidth from "../../hooks/useWindowWidth";
import { trackPage } from "../../utils/analytics.js";
import { ObtHeader } from "../../components/ObtHeader";
import { ObtFooter } from "../../components/ObtFooter";
import { ObtViewButtons } from "../../components/ObtViewButtons";
import { StoryMode } from "../../components/StoryMode";
import { Sections } from "../../components/Sections";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { CardSlider } from "../../components/CardSlider";
import { GetAllSections } from "../../utils/GetAllSections";
import useFileDownloader from "../../hooks/useFileDownloader";
// import { Portal } from "../../components/Portal";
import { AudioPlaylist } from "../../components/AudioPlaylist";
import { AppData } from "../../components/AppContext";
import { STORY_MODE, BOOK_TYPE_RESOURCES, BOOK_TYPE_EXTRAS } from '../../constants.js';
import "./style.css";

export const SectionsPage = () => {
  const { dataBreakpointsMode } = useWindowWidth();

  const { storyMode, currentBible, currentBook, currentMovement, 
        currentMovementIds, currentMovementNames, currentChapter, 
        currentOBTResource, currentResource, currentResourceName, navigateFrom, 
        bookType, fileDownLoader,
        showPlayList, isOpenPlayer} = AppData();

  const isExtras = bookType === BOOK_TYPE_EXTRAS;
  const isStoryMode = isExtras || (storyMode === STORY_MODE);  // Treat extras always as story mode, no chapters
  const isResourceMode = (navigateFrom === BOOK_TYPE_RESOURCES);        
  
  const headingName = isResourceMode ? currentResourceName : 
                      isStoryMode ? currentMovementNames[currentMovement] : "Chapter " + currentChapter;  
  
  // For file downloads
  const [downloadFile, downloaderComponentUI] = useFileDownloader(); 
  fileDownLoader.current = downloadFile;
 
  const parentCode = isResourceMode?currentMovementIds[currentResource]:isStoryMode?currentMovementIds[currentMovement]:currentChapter;
  const parent = (isResourceMode || isStoryMode)?"movements":"chapters";
  const sliderType = isResourceMode ? BOOK_TYPE_RESOURCES : (isStoryMode?"movement":"chapter") ;  
  const cardType = isResourceMode ? BOOK_TYPE_RESOURCES : "section"; 
  const bibleId = isResourceMode ? currentOBTResource['id'] : currentBible['id'];
  const { sourceId, sections, notes, isPending, isError, error } = GetAllSections(bibleId, currentBook, parent, parentCode, currentResource, navigateFrom, bookType);
  const classPlayList = showPlayList ? "with-playlist" : "";
  // Temp for testing loading effects ...
  // let isPending = true, sourceId="JON", sections = [], isError = false, error = null;
  const parentValue = `${parent}-${isStoryMode?currentMovement:currentChapter}`;
  useEffect(() => {
    // Trigger page metrics when mode changes
    const thisPage = "sections";    
    trackPage(thisPage, storyMode, currentBook, parentValue, headingName);
  }, [storyMode, isStoryMode, currentBook, parentValue, headingName]);
  
  return (
    <>
    <div className={`sections-page background-wallpaper ${dataBreakpointsMode} ${classPlayList}`} data-breakpoints-mode={dataBreakpointsMode}>

      <div className="left-wrapper">
        <div className="hero-header-section">
          <ObtHeader
            breakpoint={dataBreakpointsMode}
            className="obt-header-navigation-menu"
          />
        </div>
        
        <div className={`frame-tabs-container ${dataBreakpointsMode}`}>
          <div className={`frame-tabs frame-tabs-${dataBreakpointsMode}`}>        
            <Breadcrumbs page="sections"/>

            <div className="horizontal-tabs">          
              {cardType === "section" && <StoryMode className="story-mode-toggle" />}
              
              <div className="button">
                <ObtViewButtons className="obt-view-buttons" />
              </div>
            </div>
          </div>
        </div>

        <div className="frame-title-container">
          <div className={`heading-4 ${dataBreakpointsMode}`}>{headingName}</div>
        </div>

        <div className="main-content-area">
            <div className="left-frame" >
              
              <CardSlider bibleId={bibleId} cardType={sliderType} />                             
                                    
              {isError && <div>{`An error has occurred: ${error.message}`}</div>}

              <Sections                 
                className="sections" 
                cardType={cardType}
                bookId={sourceId} 
                isPending={isPending} 
                sections={sections}
                notes={notes}
                downloadFile={downloadFile}/>
            
            </div> 
        </div>
        <div className="obt-footer">        
          <div className="container-footer">
              <ObtFooter  breakpoint={dataBreakpointsMode} />
          </div>
        </div>
      </div>

      <div className="right-wrapper" >
        {dataBreakpointsMode === "desktop" && showPlayList &&
          <>
            {/* {isOpenPlayer && 
              <Portal setIsOpen={setIsOpenPlayer} dimensions={dimensions}>
                <AudioPlaylist 
                  bookId={sourceId}
                  dataBreakpointsMode={dataBreakpointsMode}                  
                  />       
              </Portal>
            } */}
            
            {!isOpenPlayer && 
              <div className="right-frame">
                <AudioPlaylist 
                  bookId={sourceId}
                  dataBreakpointsMode={dataBreakpointsMode}
                  />   
              </div>
            }
          </>
        }

        {dataBreakpointsMode !== "desktop" && showPlayList &&
          <AudioPlaylist 
          bookId={sourceId}
          dataBreakpointsMode={dataBreakpointsMode}
          />   
        }
        
      </div>
      {downloaderComponentUI}
    </div>

    <div>
      <br/>
      <br/>
      <br/>
    </div>

    </>
  );
};
