import React, {useState} from "react";
import { ElementCard } from "../ElementCard";
import { AppData } from "../../components/AppContext";
import { STORY_MODE, TRADITIONAL_MODE, VIEWSTATE_CAROUSEL } from '../../constants.js';
import { GetAllBooks } from "../../utils/GetAllBooks";

// Components and styles for Swiper Slider 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectCoverflow, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';

import "./style.css";

export const BookSlider = () => {         
    const [swiper, setSwiper] = useState(null);
    const { storyMode, currentBible, currentBook, setCurrentBook, setCurrentBookName, setCurrentBookNames, bookType } = AppData();
    const bibleId = currentBible['id'];
    // console.log("BookSlider bookType:", bookType);
    const { books, isPending, isError, error } = GetAllBooks(bibleId, bookType);
    // const isExtras = (bookType === BOOK_TYPE_EXTRAS);
    const isTraditionalMode = storyMode === TRADITIONAL_MODE;
  
    // Animated loader
    if (isPending) return ( <>
      {/* Center the loader */}
      <div style={{"position": "absolute", left: "50%"}}>
      <div style={{"position": "relative", left: "-50%"}}
          className="slider-loader"></div>
      </div>
      </>
    )
    
    // TBD: Add better error handling
    if (isError) return 'An error has occurred: ' + error.message

    // Values for slider
    var index = books.findIndex(book => book.book_id === currentBook);
    var firstSlide = 0;
    if (index >= 0) {firstSlide = index}

    // const slideWidth = 180+10;
    // const screenWidth = useWindowWidth();
    // const slidesPerView = parseInt(screenWidth / slideWidth);
    
    let cardIndex = 0;
    let bookList = [];
    let bookName = "";
   
    return (    
      <div className='book-carousel'>

        <Swiper 
              modules ={[Pagination, Navigation, EffectCoverflow]}
              initialSlide={firstSlide}
              //slidesPerView={'auto'}
              slidesPerView={5}
              spaceBetween={0}
              grabCursor={true}
              centeredSlides={true}
              slideToClickedSlide={true}
              effect={'coverflow'}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,                
                depth: 150,
                modifier: 2.5,
              }}
              pagination={{ el: '.swiper-pagination', clickable: true }}
              navigation={{
                clickable: true,
              }}
              onSlideChange={() => {          
                if (swiper?.realIndex !== undefined && swiper.realIndex !== null) {
                  setCurrentBook(bookList[swiper.realIndex]?.id?bookList[swiper.realIndex].id:null);
                  setCurrentBookName(bookList[swiper.realIndex]?.name?bookList[swiper.realIndex].name:null)
                  setCurrentBookNames(bookList[swiper.realIndex]?.names?bookList[swiper.realIndex].names:null)
                }}
              }
              onSwiper={setSwiper}
              className="swiper-container"
          >
              
        {books && books.map(book => 
           {
            bookName = isTraditionalMode ? book.name : book.name_alt;
            bookList[cardIndex] = { id:book.book_id, name:bookName, names:{[STORY_MODE]:book.name_alt, [TRADITIONAL_MODE]:book.name } }
            return (
            <SwiperSlide data-hash={book.book_id} key={book.book_id}>            
              <div className="book-carousel-image" key={book.name}>
                  <ElementCard      
                  cardCode={book.book_id}                
                  cardType={bookType}
                  cardIndex={cardIndex++}
                  cardName={bookName}
                  cardNames={{[STORY_MODE]:book.name_alt, [TRADITIONAL_MODE]:book.name }}
                  cardDuration={book.duration?book.duration:"-:--"}
                  cardImageClassName={`card-image-${VIEWSTATE_CAROUSEL}`}
                  cardImage={book.images[0]?book.images[0].url?book.images[0].url:null:null}
                  cardAudioTitle={isTraditionalMode ? ( book.title_audio?book.title_audio[0]?.url?book.title_audio[0].url:null:null ) 
                                                    : ( book.title_audio_alt?book.title_audio_alt[0]?.url?book.title_audio_alt[0].url:null:null ) }
                  cardState={VIEWSTATE_CAROUSEL}
                  overlapGroupClassName="carousel-card-instance"
                  subheadingClassName="element-card-subheading"      
                  showDownload={false}
                  showPlay={false}
                  isSlider={true}
                  setCurrentCardAction={() => setCurrentBook}
                  showAddToPlayList={false}
                />              
              </div>     
            </SwiperSlide>  
            )
           }
        )}  
        {/* Position the slider dots below the slider */}
          <div className="slider-controller">
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      </div>
    );
}
