// PlaylistContext
import React, { createContext, useReducer, useContext, useEffect, useRef } from 'react';
import { savePlaylist, getPlaylist } from '../../utils/playlistFunctions.js';

// NOTE: playList structure is expected to have the following elements:
// {
//   "cardIndex":,
//   "id":,
//   "cardType":  oneOf("section", "note")
//   "cardMode":,
//   "cardBook":,
//   "cardParent":,
//   "cardSection":,
//   "cardName":,
//   "cardPassageAudio":,
//   "cardPassageFileName":,
//   "cardPassage":,
//   "cardCategory":,
//   "cardCategoryId":,
//   "cardDuration":,
//   "cardImage":,
//   "cardAudioTitle":,
//   "cardColor":,
//   "cardShow":true/false
// }

const PlaylistContext = createContext();

export const PlayListData = () => useContext(PlaylistContext);

const sortByIndex = (state) => {
  if (state && state.length>1 ) {
    return state.sort((a, b) => {
      if (a.cardIndex !== undefined && b.cardIndex !== undefined) {
        if (a.cardIndex < b.cardIndex) {
          return -1;
        }
        if (a.cardIndex > b.cardIndex) {
          return 1;
        }
        return 0;
      } else {
        return 0;
      }
    });
  }
}

const findByIndex = (state, payload) => {
  if (state && state.length>1 ) {
    // Only match indexes within the same section
    return state.find(card => card.cardIndex === payload.cardIndex);
  }
}

const playlistReducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      // Add to end of playlist - does not remove duplicates
      // console.log("playlistReducer ADD", action.payload)
      if (action.payload.id !== undefined) {
        if (state && state.length>0) { 
          return [...state, action.payload];
        } else {
          return [action.payload];
        }
      } else {
        return state;
      }

    case 'REMOVE_ID':
      // Removes card from list, matched by the payload id
      // console.log("playlistReducer REMOVE_ID", action.payload)
      if (state && state.length>0) {
        if (action.payload.id !== undefined) {
          return state.filter(card => card.id !== action.payload.id);
        } else {
          return state;
        }
      } else {
        return [];
      }

    case 'REMOVE_SECTION':
      // Removes cards from list, matched by the payload section number
      // console.log("playlistReducer REMOVE_SECTION", action.payload)
      if (state && state.length>0) {
        if (action.payload.section !== undefined) {
          return state.filter(card => card.cardSection !== action.payload.section);
        } else {
          return state;
        }
      } else {
        return [];
      }

    case 'CLEAR_ALL':
      // Deletes all cards in playlist, returns empty list
      // console.log("playlistReducer CLEAR_ALL")      
      return [];
      
    case 'REPLACE':
      // Replaces card if found, else need to insert a new card, but insert according to its cardIndex
      //  If the cardIndex already exists, increase all subsequent indexes by 1 to avoid duplicate index
      //   then insert new card 
      //   and finally sort the list by cardIndex
      //   This logic allows us to add notes related to an existing section and retain their order
      //   "cardIndex" retains the sequence of all cards for a single movement or chapter (passage + audio notes)
      // console.log("playlistReducer REPLACE", action.payload)
      if (state && state.length>0) {
        if (action.payload.id !== undefined) {
          let newState = state.filter(card => card.id !== action.payload.id );
          // console.log("playlistReducer -REPLACE: ", action.payload, newState)
          if (newState && newState.length > 0 ) {
            // Check whether another card with the same index already exists for this book
            let thisCard = findByIndex(state, action.payload);
            // console.log("playlistReducer REPLACE - thisCard found?", thisCard);
            let finalState = [];
            if (thisCard) {
              // If it does, increase the index of all subsequent cards
              let keepState = state.filter(card => card.cardIndex < action.payload.cardIndex);
              // console.log("keepState:", keepState)
              let changedState = state.filter(card => card.cardIndex >= action.payload.cardIndex);
              // console.log("changedState:", changedState)
              if (changedState && changedState.length > 0 ) {
                changedState.forEach(card => {
                  card.cardIndex += 1;
                });
                // console.log("new changedState:", changedState)
              }
              // concatenate arrays
              let newArray = [];
              if (keepState && keepState.length > 0) {
                newArray = [...keepState,...changedState, action.payload];
              } else {
                newArray = [...changedState, action.payload];
              }
              // console.log("playlistReducer REPLACED newArray:", newArray);
              // Sort the list by cardIndex
              finalState = sortByIndex(newArray);
            } else {
               // console.log("playlistReducer REPLACED before sort:", [...newState, action.payload])
               finalState = sortByIndex([...newState, action.payload])
               
            }
            // console.log("playlistReducer REPLACED finalState:", finalState)
            return finalState;
          } else {
            // If empty list, no match found so just add the new state
            // console.log("playlistReducer REPLACED:", [action.payload])
            return [...state, action.payload];
          }
        } else {
          return state;
        }
      } else {
        // If empty list, just add the new state
        // console.log("playlistReducer REPLACED:", [action.payload])
        return [action.payload];
      }

      case 'REFRESH':
      // Scans playlist and removes any notes that have disabled categories
      // payload contains the category list with "id" and "toggle" property set to true/false to indicate active categories
      // Do not remove any cards that have an overrideDelete flag set
      // console.log("playlistReducer REFRESH", action.payload.categoryList)
      if (state && state.length>0) {
        if (action.payload.categoryList !== undefined && action.payload.categoryList && action.payload.categoryList.length > 0) {
          // Pull the disabled category IDs only
          let filteredCategories = action.payload.categoryList.filter(c => c.toggle === false);
          let disabledCategories = filteredCategories.map(obj => obj.id);
          if (disabledCategories.length>0) {  
            // Filter out cards with those disabled categories
            let newState = state.filter(card => card.overrideDelete || !disabledCategories.includes(card.cardCategoryId));
              // console.log("playlistReducer REFRESH newState:", newState)
              return newState;
          } else {
            // No categories are disabled so nothing to filter out
            return state;
          }
        } else {
          return state;
        }
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const PlaylistProvider = ({ children }) => {
  const [playList, dispatch] = useReducer(playlistReducer, getPlaylist());
  const playListRef = useRef();
  
  useEffect(() => {
    savePlaylist(playList);
  }, [playList]);

  return (
    <PlaylistContext.Provider value={{ playList, dispatch, playListRef }}>
      {children}
    </PlaylistContext.Provider>
  );
};


