
import React, { useEffect } from "react";
import { ElementCard } from "../ElementCard";
import { AppData } from "../../components/AppContext";
import { GetAllMovements } from "../../utils/GetAllMovements";
import "./style.css";

const placeholderCards = [  
  {
    movement: 1,
    title: "_"
  },
  {
    movement: 2,
    title: "_"
  }
];

export const Movements = ({ className }) => {
  const { storyMode, viewState, currentBible, currentBook, setCurrentMovement, setCurrentMovementNames, setCurrentMovementIds, bookType } = AppData();
  const bibleId = currentBible['id'];
  const { movements, isPendingMovements, isErrorMovements, errorMovements } = GetAllMovements(bibleId, currentBook, bookType);

  useEffect(() => {
    //console.log('Movements rendering: ', movements);
    if (!isPendingMovements) {
      if (movements && movements.length > 0) {
        let movementList = [];
        let movementIdList = [];
        for (let card in movements) {
          movementList[movements[card].movement] = movements[card].title;
          // Use movement id for new API, movement number for old (MVP) API
          movementIdList[movements[card].movement] = movements[card].id?movements[card].id:movements[card].movement;
        }        
        setCurrentMovementNames(movementList);
        setCurrentMovementIds(movementIdList);
      }
    }
          
  },[isPendingMovements, movements, setCurrentMovementNames, setCurrentMovementIds]);
  
  // TBD: Add better error handling
  if (isErrorMovements) return 'Error fetching movements: ' + errorMovements.message
  
  return (
    <div className={`movements ${storyMode} ${viewState} ${className}`}>
      <div className="frame-movements">
        {isPendingMovements && placeholderCards.map(card => {          
          return (
            <span className="skeleton-box" key={card.movement}>
              <ElementCard
                key={`${currentBook}-Movement-${card.movement}`}
                cardType="movement"
                cardName={card.title}
                cardAnimation = "shimmer"
                cardIndex={card.movement}
                cardDuration={null}
                cardImageClassName={`card-image-${viewState}`}
                cardAudioTitle={null}
                cardState={viewState}  
                showPlay={false} 
                showDownload={false}               
                obtDownloadClassName={`card-download-${viewState}`}
                obtQuickPlayClassName={`card-quickplay-${viewState}`}
                overlapGroupClassName="instance-node"
                subheadingClassName="element-card-subheading"
                showAddToPlayList={false}
              />
            </span>
          )
        })}

        {!isPendingMovements && movements && movements.map(card => {          
              return (
              <ElementCard
                key={`${currentBook}-Movement-${card.movement}`}
                cardType="movement"
                cardName={card.title}
                cardIndex={card.movement}
                cardDuration={card.duration}
                cardChapter={card.chapter_start}
                cardImageClassName={`card-image-${viewState}`}
                cardImage={card.images[0]?card.images[0].url?card.images[0].url:null:null}                                
                cardAudioTitle={card.title_audio?card.title_audio[0]?.url?card.title_audio[0].url:null:null}
                cardState={viewState}
                showPlay={false}
                showDownload={false}
                obtDownloadClassName={`card-download-${viewState}`}
                obtQuickPlayClassName={`card-quickplay-${viewState}`}
                overlapGroupClassName="instance-node"
                subheadingClassName="element-card-subheading" 
                navigateTo="/sections" 
                setCurrentCardAction={() => setCurrentMovement}
                showAddToPlayList={false}
              />
              )
            })}
      </div>      
    </div>
  );
};

