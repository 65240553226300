import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import { ElementCard } from "../ElementCard";
import { AppData } from "../../components/AppContext";
import { VIEWSTATE_CAROUSEL, BOOK_TYPE_RESOURCES } from '../../constants.js';
import { GetAllCards } from "../../utils/GetAllCards";

// Components and styles for Swiper Slider 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectCoverflow, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';

import "./style.css";

export const CardSlider = ( {bibleId, cardType} ) => {
    const [swiper, setSwiper] = useState(null);

    const { storyMode, currentBook, currentResource, currentMovement, setCurrentMovement, currentChapter, setCurrentChapter, setCurrentImage, bookType } = AppData();      
    const chapterMode = (cardType === "chapter")?true:false;

    const currentCard = (chapterMode)?currentChapter:currentMovement;
    // console.log("CardSlider1 cardType: " + cardType);
    // console.log("CardSlider1 currentCard: " + currentCard);
    // console.log("CardSlider1 currentMovement: " + currentMovement);
    // console.log("CardSlider1 currentChapter: " + currentChapter);
    const codeName = (chapterMode)?"chapter":"movement";
    const setCurrentCard = (chapterMode)?setCurrentChapter:setCurrentMovement;
    const setCurrentSyncCard = (chapterMode)?setCurrentMovement:setCurrentChapter;       
    
    const { sourceId, cards, isPending, isError, error } = GetAllCards(bibleId, currentBook, currentResource, cardType, bookType);

    // to prevent dependency warning on useEffect:
    const thisToggleSlider = useRef();

    const toggleSlider = () => {
      if (swiper && cards && cards.length>0) {
        // Switch to the correct movement or chapter when the story mode toggles
        // console.log("toggleSlider - codeName: " + codeName);
        // console.log("toggleSlider - currentCard: " + currentCard);
        // console.log("toggleSlider - cards: ", cards);
        let index = cards.findIndex(card => card[codeName] === parseInt(currentCard));  
        //console.log("toggleSlider index: ", index);
        if (index >= 0) {
          swiper.slideTo(index, 0);
        } else {
          // If something goes wrong, reset to the first slide
          setCurrentMovement(1);
          setCurrentChapter(1);
          swiper.slideTo(0, 0);
        }
        
      }
    }
    thisToggleSlider.current = toggleSlider;

    useEffect(() => {
      // adjust the slider when story mode toggles between movements and chapters
      thisToggleSlider.current();
    }, [storyMode]);


    function setCards(cardType, card) {
      // Whenever movement changes, keep chapter in sync and vice-versa
      // This will make it possible to toggle story mode without losing the user's place
      // console.log("setCards - card: ", card);
      if (card) {
        if (cardType === "movement") {
          setCurrentChapter(card.chapter_start);
          // console.log("setCards - setCurrentChapter: " + card.chapter_start);
          setCurrentMovement(card.movement);
          // console.log("setCards - setCurrentMovement: " + card.movement);
        } else if (cardType === "chapter") {
          setCurrentChapter(card.chapter);
          // console.log("setCards - setCurrentChapter: " + card.chapter);
          setCurrentMovement(card.movement_start);
          // console.log("setCards - setCurrentMovement: " + card.movement_start);
        }
      }
    }

    // Animated loader
    if (isPending) return ( <>
      {/* Center the loader */}
      <div style={{"position": "absolute", left: "50%"}}>
      <div style={{"position": "relative", left: "-50%"}}
          className="slider-loader"></div>
      </div>
      </>
    )
    
    // TBD: Add better error handling
    if (isError) return 'An error has occurred: ' + error.message

    if (!isPending && !isError && cards) {
      // console.log("CardSlider2 cardType: " + cardType);
      // console.log("CardSlider2 currentCard: " + currentCard);
      // console.log("CardSlider2 currentMovement: " + currentMovement);
      // console.log("CardSlider2 currentChapter: " + currentChapter);
      let index = cards.findIndex(card => card[codeName] === parseInt(currentCard));  
      //console.log("CardSlider2 index: ", index);
      let firstSlide = 0;
      if (index >= 0) {firstSlide = index}
      let cardIndex = 0;
      let cardList = [];
      let cardListSync = []; // used to meep movement and chapter in sync; not applicable for other card types
      let cardImages = [];
      let cardCode = "";
          
      return (    
        <div className='card-carousel'>
          <Swiper 
              modules ={[Pagination, Navigation, EffectCoverflow]}
              initialSlide={firstSlide}
              //slidesPerView={'auto'}
              slidesPerView={5}
              spaceBetween={0}
              grabCursor={true}
              centeredSlides={true}
              slideToClickedSlide={true}
              effect={'coverflow'}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 150,
                modifier: 2.5,
              }}
              pagination={{ el: '.swiper-pagination', clickable: true }}
              navigation={{
                clickable: true,
              }}
              onSlideChange={() => {          
                if (swiper?.realIndex !== undefined && swiper.realIndex !== null) {
                  setCurrentCard(cardList[swiper.realIndex]?cardList[swiper.realIndex]:null);
                  //console.log('Swiper - onSlideChange - setCurrentCard: ', cardList[swiper.realIndex]?cardList[swiper.realIndex]:null);
                  setCurrentSyncCard(cardListSync[swiper.realIndex]?cardListSync[swiper.realIndex]:null);
                  //console.log('Swiper - onSlideChange - setCurrentSyncCard: ', cardListSync[swiper.realIndex]?cardListSync[swiper.realIndex]:null);
                  setCurrentImage(cardImages[swiper.realIndex]?cardImages[swiper.realIndex]:null);
                }}
              }
              onSwiper={setSwiper}
              className="swiper-container"
            >
            {cards && cards.map(card => 
              {
                cardCode = ((chapterMode)?card.chapter:card.movement).toString();
                cardList[cardIndex] = cardCode;
                cardListSync[cardIndex] = ((chapterMode)?card.movement_start:card.chapter_start?card.chapter_start:0).toString();
                cardImages[cardIndex] = card.images[0]?card.images[0].url?card.images[0].url:null:null;
                return (
                  <SwiperSlide data-hash={cardCode} key={cardCode}>
                    <div className="card-carousel-image" key={`${cardType}-${cardCode}`}>
                        <ElementCard   
                        cardCode={cardCode}   
                        cardType={cardType}
                        key={`${sourceId}-${cardType}-${cardCode}`}
                        cardName={card.title}
                        cardIndex={cardIndex++}
                        cardChapter={(chapterMode)?card.chapter:card.chapter_start}
                        cardMovement={(chapterMode)?card.movement:card.movement_start}
                        //cardDuration={card.duration}                
                        cardImageClassName={`card-image-${VIEWSTATE_CAROUSEL}`}
                        cardImage={card.images[0]?card.images[0].url?card.images[0].url:null:null}
                        cardAudioTitle={card.title_audio?card.title_audio[0]?.url?card.title_audio[0]?.url:null:null}
                        cardState={VIEWSTATE_CAROUSEL}
                        overlapGroupClassName="carousel-card-instance"
                        subheadingClassName="element-card-subheading"
                        showDownload={false}
                        showAddToPlayList={false}
                        showPlay={false}
                        iconAfter={true}
                        setCurrentCardAction={() => setCards(cardType,card)}
                      />              
                    </div> 
                  </SwiperSlide>
                );               
              }
            )}
            {/* Position the slider dots below the slider */}
            <div className="slider-controler">
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>
        </div>        
      );
    } else {
      return null;
    }
}

CardSlider.propTypes = {
  cardType: PropTypes.oneOf(["movement","chapter",BOOK_TYPE_RESOURCES])
};