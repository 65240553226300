import { useState, useEffect } from "react";

export function getStorageValue(key, defaultValue) {
  let initial = null;
  try {
    // getting stored value for the session
    const saved = sessionStorage.getItem(key);
    if (saved !== undefined && saved !== null && saved !== "" && saved !== "undefined" && saved) {
      // console.log("saved",saved)
      initial = JSON.parse(saved);
    }
  } catch (e) {
    console.error("Error reading session storage value", key, e);
  } finally {
    return initial || defaultValue;
  }
}

export function setStorageValue(key, value) {
  try {
    // Save the value in session storage as json
    sessionStorage.setItem(key, JSON.stringify(value));
    return false;
  } catch (e) {
    console.error("Error saving session storage value", key, value, e);
    return true;
  }
}

export const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

// export default useSessionStorage;