
import React, {useRef }from "react";
import { ObtResourceLevel1 } from "../ObtResourceLevel1";
import { AppData } from "../../components/AppContext";
import { pushSection, pushMovementNote } from "../../utils/listFunctions.js";
import { downloadAudio } from "../../utils/downloadFunctions.js";
import { GetAllChapters } from "../../utils/GetAllChapters";
import { GetAllMovements } from "../../utils/GetAllMovements";
import { VIEWSTATE_SMALL, BOOK_TYPE_BOOKS } from '../../constants.js';
import "./style.css";

const placeholderCards = [  
  {
    id: 1,
    chapter: 1,
    title: "_"
  }
];

// This component applies only to OBT Helps
// It pulls all the chapters for the selected parent book (as selected from a ResourceCard card)
// It displays a series of OBTResourceLevel1 cards representing each chapter in the book
//
//    OBT Resource Hierarchy:
//    Home -> Resources (get books) -> ResourceCard (Book UI) 
//            -> ResourceChapters (get chapters) -> ResourceLevel1 (Chapter UI) 
//                -> ResourceSections (get sections) -> ResourceLevel2 (Section UI)
// 
export const ObtResourceChapters = ({ 
  className, 
  viewState=VIEWSTATE_SMALL, 
  bookId, 
  parentImage
   }) => {
  const { currentBible, fileDownLoader, isOBTHelps } = AppData();
  const bibleId = currentBible['id'];
  const bookType = BOOK_TYPE_BOOKS;
  const { chapters, isPendingChapters, isErrorChapters, errorChapters } = GetAllChapters(bibleId, bookId, true);
  // no sections - only need audio notes at movement level
  // TBD:  Get 'chapters' API to return movement level and section level audio notes
  const { movements, movementNotes, isPendingMovements, isErrorMovements, errorMovements } = GetAllMovements(bibleId, bookId, bookType, false);  

  /* List cards will track playable items and will be formatted as 
          {
              "key": unique key for the book
              "id": id for this type of card as returned by the API
              "index": index in the playlist,
              "section": section index in the playlist (may not be used)
              "sectionId": section id
              "title": title / name
              "passageAudio": list of audio files - filtered out chapter/title categories if disabled
              "passageAudioUnfiltered": list of all audio files regardless of category filters
              "passageFileName": list of audio file names - filtered out chapter/title categories if disabled
              "passageFileNameUnfiltered": list of all audio file names regardless of category filters
              "image": image file,
              "audioTitle": title audio,
              "passage": if note, this is the passage number
              "category": category name for notes,
              "categoryId": category id for notes,
              "color": color for note category,
              "duration": play duration
              "show":true/false to show this card - used to filter notes by categories
            }
  */
  const chapterList = useRef([]);
  const movementList = useRef([]);
  const passageList = useRef([]);
  const notesList = useRef([]);
  passageList.current["chapters"] = [];
  passageList.current["movements"] = [];

  // Temp for testing loading ...
  // let isPendingChapters = true, chapters = [], isErrorChapters = false, error = null; 
    
  // TBD: Add better error handling
  if (isErrorChapters) return 'Error fetching chapters: ' + errorChapters.message
  if (isErrorMovements) return 'Error fetching movements: ' + errorMovements.message

  // Count sections and tracks
  let cardTrackIndex = 0;  // for cardIndex value, which begins with track 1  

  /**
   * 
   * @param {*} chapterCard 
   */
  const pushChapterCard = (thisKey,chapterCard) => {
    let newCard = chapterCard;
    newCard.key = thisKey;
    chapterList.current.push(newCard);
    // console.log("chapterList", chapterList.current)
    let parent = 'chapters';
    if (chapterCard.sections && chapterCard.sections.length>0) {
      passageList.current[parent][chapterCard.id]=[];
      notesList.current[chapterCard.id]=[];
      chapterCard.sections.forEach(card => {
        cardTrackIndex++;
        pushSection(card,passageList.current[parent][chapterCard.id],notesList.current[chapterCard.id],cardTrackIndex,bibleId,bookId,parent,chapterCard.chapter,parentImage,isOBTHelps);
      });
    }
  }

  /**
   * 
   * @param {*} movementCard 
   */
  const pushMovementCard = (thisKey,movementCard) => {
    let newCard = movementCard;
    let notes = movementCard.audio_notes;
    let movementId = movementCard.id;
    let parentImage = movementCard.image;
    newCard.key = thisKey;
    movementList.current.push(newCard);
    // console.log("movementList", movementList.current)
    let parent = 'movements';
    if (notes && notes.length > 0) {
      passageList.current[parent][movementId]=[];
      // console.log("Loading notes", notes);
      notes.sort((a, b) => a.passage - b.passage);
      notes.forEach(card => {
        cardTrackIndex++;
        pushMovementNote(card,passageList.current[parent][movementId],cardTrackIndex,bibleId,bookId,parent,movementId,parentImage);
        // console.log("Movement notes:", passageListRef.current);
      });
    }
  }  

  /**
   * 
   * @param {*} key : unique key to identify the resource
   */
  const downloadResource = async (key, parent) => {
    // console.log('downloading resource key ', key);
    let thisKey = null;
    let filteredList = null;
    let thisCard = null;
    let thisMainList = (parent === "chapters") ? chapterList.current : movementList.current;
    let thisPassageList = passageList.current[parent];
         
    // Lookup the passages for the desired key 
    //  - may be either a scripture section with multiple passages or a single note
    filteredList = thisMainList.filter((card) => card.key === key); 
    if (filteredList && filteredList.length>0) {
      thisCard = filteredList[0];
      thisKey = thisCard.id;
    }

    if (thisKey) {
      // Lookup the passages for the desired key 
      //  - may be either a scripture section with multiple passages or a single note
      let filteredList1 = [];
      let filteredList2 = [];

      if (thisPassageList[thisKey]) {
        filteredList1 = thisPassageList[thisKey];
      }
      if (notesList.current[thisKey]) {
        filteredList2 = notesList.current[thisKey];
      }
      filteredList = filteredList1.concat(filteredList2);
      // console.log("filteredList", filteredList)
      
      if (filteredList.length > 0) {        
        let downloadName = "";
        if (parent === "chapters"){
          if (thisCard.title) {
            downloadName = thisCard.title;
          } else {
            downloadName = "Chapter-"+thisCard.chapter;
          }          
          downloadAudio('chapter', fileDownLoader.current, filteredList, bookId, downloadName);
        } else {
          if (thisCard.title) {
            downloadName = thisCard.title;
          } else {
            downloadName = "Movement-"+thisCard.movement;
          }          
          downloadAudio('list', fileDownLoader.current, filteredList, bookId, downloadName);
        }
      }
    }
  } 

  return (
    <div className={`resource-chapters ${viewState} ${className}`}>
      <div className="frame-resource-chapters">

        {isPendingChapters && placeholderCards.map(card => {          
          return (
            <span className="skeleton-box" key={card.id}>
              <ObtResourceLevel1
                key={`${bookId}-Chapter-${card.id}`}
                cardKey={`${bookId}-Chapter-${card.id}`}
                cardType="chapters"
                cardCode={card.id}
                cardName={card.title}
                cardAnimation = "shimmer"
                cardDuration={null}
                cardImageClassName={`card-image-${viewState}`}
                cardAudioTitle={null}
                showPlay={false}
                showDownload={false}
                bookType={bookType}
              />
            </span>
          )
        })}

        {!isPendingMovements && movements && (
          <>
            {(() =>{
              movementList.current = [];
              let parent = "movements";
              return movements.map(movement => {
                // console.log("movement:", movement, "movementNotes:", movementNotes[movement.id]);
                if (movementNotes[movement.id] && movementNotes[movement.id].length > 0) {
                  let thisKey = `${bookId}-Movement-${movement.id}`;
                  pushMovementCard(thisKey, movement);
                  return (
                    <ObtResourceLevel1
                      key={thisKey}
                      className="resource-link-instance"
                      bookType={bookType}
                      cardBookId={bookId}
                      cardType={parent}
                      cardCode={movement.id}
                      cardName={movement.title}
                      cardDuration={null}
                      cardImageClassName={`card-image-${viewState}`}
                      cardImage={movement.images[0]?movement.images[0].url?movement.images[0].url:parentImage:parentImage}
                      cardAudioTitle={movement.title_audio?movement.title_audio[0]?.url?movement.title_audio[0]?.url:null:null}
                      showPlay={false}
                      showDownload={true}
                      downloadAction={()=>{downloadResource(movement.key,parent)}}
                    />
                  )
                } else {
                  return null;
                }
              });
            })()}
          </>
        )}

        {!isPendingChapters && chapters && (
          <>
            {(() =>{
              chapterList.current = [];
              let parent = "chapters";
              return chapters.map(card => {
                let thisKey = `${bookId}-Chapter-${card.id}`
                pushChapterCard(thisKey,card);
                return (
                  <ObtResourceLevel1
                    key={`${bookId}-Chapter-${card.id}`}
                    className="resource-link-instance"
                    bookType={bookType}
                    cardBookId={bookId}
                    cardType={parent}
                    // cardCode={card.id}  // TBD: switch to ID when API is fixed, use chapter number for now
                    cardCode={card.chapter}  // TBD: switch to ID when API is fixed, use chapter number for now
                    cardName={card.title}
                    cardDuration={null}  //TBD
                    cardImageClassName={`card-image-${viewState}`}
                    cardImage={card.images[0]?card.images[0].url?card.images[0].url:parentImage:parentImage}
                    cardAudioTitle={card.title_audio?card.title_audio[0]?.url?card.title_audio[0]?.url:null:null}
                    showPlay={false}
                    showDownload={true}
                    downloadAction={()=>{downloadResource(card.key,parent)}}
                  />
                )
              });
            })()}
          </>
        )}
      </div>      
    </div>
  );
};

