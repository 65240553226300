import {createContext, useContext, useState, useRef, useEffect } from 'react';
import { localSettings } from "../../utils/localSettings.js";
import { useSessionStorage } from "../../hooks/useSessionStorage.js";
import { getLocalCategories } from "../../utils/categoryFunctions.js";
import { isApple } from "../../utils/isApple.js";
import { STORY_MODE, TRADITIONAL_MODE, VIEWSTATE_SMALL, VIEWSTATE_LARGE, NOTE_MODE_OPENED, NOTE_MODE_CLOSED } from '../../constants.js';
import { AnalyticsBrowser } from '@segment/analytics-next'

const AppContext = createContext();

export const AppData = () => useContext(AppContext);

// We can export this instance to share with rest of our codebase.
export const segmentAnalytics = AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_KEY });

export const AppWrapper = ({children}) => {
    /* Define states to be shared across components and screens */   

    // To test for apple devices - must be done asynchronously
    const [isAppleDevice, setIsAppleDevice] = useState(false);
    useEffect(() => {
        const checkPlatform = async () => {
        const result = await isApple();
        console.log("isAppleDevice: ", result);
        setIsAppleDevice(result);
        };
        checkPlatform();
    }, []);

    // Start with the saved language code 
    let thisLanguage = localSettings('getItem', 'language');
    // console.log("localsettings thisLanguage", thisLanguage);
    if (thisLanguage === undefined || thisLanguage === null || thisLanguage.iso === undefined ) {
        let defaultLanguage = {iso:process.env.REACT_APP_LANGUAGE_ISO, name:process.env.REACT_APP_LANGUAGE_NAME};
        console.log("Using default Language", defaultLanguage);
        thisLanguage = {iso:defaultLanguage.iso, name:defaultLanguage.name};
        localSettings('setItem', 'language', thisLanguage);
    }
    const [currentLanguage, setCurrentLanguage] = useState(thisLanguage);

    // Start with the saved bible values 
    let thisBible = localSettings('getItem', 'bible');
    // console.log("localsettings thisBible", thisBible);
    if (thisBible === undefined || thisBible === null || thisBible.id === undefined ) {
        let defaultBible = {id:process.env.REACT_APP_BIBLE_ID, name:process.env.REACT_APP_BIBLE_NAME};
        console.log("Using default Bible", defaultBible);
        thisBible = {id:defaultBible.id, name:defaultBible.name};
        localSettings('setItem', 'bible', thisBible);
    }
    const [currentBible, setCurrentBible] = useState(thisBible);

    const categoryList = useRef();
    let savedCategories = getLocalCategories(thisBible.id);    
    if (savedCategories) {
        categoryList.current = savedCategories;
    }

    // TEMPORARY: pull from .env - this needs to use API and local settings similar to the bible settings
    const [currentOBTResource, setCurrentOBTResource] = useState({id:process.env.REACT_APP_RESOURCE_ID, name:process.env.REACT_APP_RESOURCE_NAME});
    
    // const [currentBook, setCurrentBook] = useState("GEN");
    // const [currentBookName, setCurrentBookName] = useState("The Beginning");
    const [currentBook, setCurrentBook] = useSessionStorage("currentBook", "");
    const [currentBookName, setCurrentBookName] = useSessionStorage("currentBookName", "");
    const [currentBookNames, setCurrentBookNames] = useSessionStorage("currentBookNames", {[STORY_MODE]:"", [TRADITIONAL_MODE]:""});
    const [currentExtra, setCurrentExtra] = useSessionStorage("currentExtra", "");
    const [currentExtraName, setCurrentExtraName] = useSessionStorage("currentExtraName", "");
    const [bookType, setBookType] = useSessionStorage("bookType", "");
    
    const [currentResource, setCurrentResource] = useState("");
    const [currentResourceName, setCurrentResourceName] = useState("");
    const [currentMovement, setCurrentMovement] = useState(1);
    const [currentMovementIds, setCurrentMovementIds] = useState([0]);
    const [currentMovementNames, setCurrentMovementNames] = useState([""]);
    const [currentChapter, setCurrentChapter] = useState(1);
    const [currentSection, setCurrentSection] = useState(1);
    const [currentImage, setCurrentImage] = useState();
    
    const [noteMode, setnoteMode] = useState(NOTE_MODE_CLOSED);
    const toggleNoteMode = () => setnoteMode(noteMode===NOTE_MODE_CLOSED?NOTE_MODE_OPENED:NOTE_MODE_CLOSED);
     
    const [storyMode, setStoryMode] = useState(STORY_MODE);
    const toggleMode = () => setStoryMode(storyMode===STORY_MODE?TRADITIONAL_MODE:STORY_MODE);
    
    const [viewState, setViewState] = useState(VIEWSTATE_LARGE);
    const toggleViewState = () => setViewState(viewState===VIEWSTATE_LARGE?VIEWSTATE_SMALL:VIEWSTATE_LARGE);   

    const [navigateFrom, setNavigateFrom] = useState('books');

    const [currentTrack, setCurrentTrack] = useState();
    const [trackIndex, setTrackIndex] = useState(1);  // tracks are indexed from 1

    const [audioLoading, setAudioLoading] = useState(false);
    const [showPlayList, setShowPlayList] = useState(false);
    const toggleShowPlayList = () => setShowPlayList(!showPlayList);

    const [isOpenCategory, setIsOpenCategory] = useState(false);

    const [isOpenResource, setIsOpenResource] = useSessionStorage("isOpenResource",[{id:"",state:false}]);
    const [isOpenResourceLevel1, setIsOpenResourceLevel1] = useSessionStorage("isOpenResourceLevel",[{book_id:"",id:"",state:false}]);
    
    const [isOpenControls, setIsOpenControls] = useState(false);

    const fileDownLoader = useRef();

    const enableControls = useRef();
    const [isPlaying, setIsPlaying] = useState(false);    
    const [isOpenPlayer, setIsOpenPlayer] = useState(false);
    const [dimensions, setDimensions] = useState([]);
       
    const isOBTHelps = process.env.REACT_APP_NAME==="OBT Helps"?true:false;

    return(
        <AppContext.Provider value={{
            storyMode, toggleMode,
            viewState, toggleViewState, setViewState,
            currentBible, setCurrentBible,
            currentOBTResource, setCurrentOBTResource,
            currentLanguage, setCurrentLanguage,
            currentBook, setCurrentBook,
            currentBookName, setCurrentBookName,
            currentBookNames, setCurrentBookNames,
            currentMovement, setCurrentMovement,
            currentMovementIds, setCurrentMovementIds,
            currentMovementNames, setCurrentMovementNames,
            currentChapter, setCurrentChapter,
            currentSection, setCurrentSection,
            currentResource, setCurrentResource,
            currentResourceName, setCurrentResourceName,
            noteMode, toggleNoteMode,
            navigateFrom, setNavigateFrom,
            currentExtra, setCurrentExtra,
            currentExtraName, setCurrentExtraName,
            currentImage, setCurrentImage,
            currentTrack, setCurrentTrack,
            trackIndex, setTrackIndex,
            audioLoading, setAudioLoading,
            showPlayList, setShowPlayList,
            toggleShowPlayList, 
            isOpenCategory, setIsOpenCategory,
            bookType, setBookType,
            isOpenResource, setIsOpenResource,
            isOpenResourceLevel1, setIsOpenResourceLevel1,
            isOpenControls, setIsOpenControls,
            fileDownLoader, enableControls,
            isPlaying, setIsPlaying, 
            isOpenPlayer, setIsOpenPlayer,
            dimensions, setDimensions,
            categoryList,isOBTHelps,
            isAppleDevice,
            segmentAnalytics
            }}>

            {children}
        </AppContext.Provider>

    )
}
