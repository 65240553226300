import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';
import { BOOK_TYPE_EXTRAS } from '../constants.js';

export const GetAllMovements = ( bibleId, bookId, bookType, withSections=false ) => {    
    let movements = [];
    let movementNotes = [];
    let {isMVP, modeParm} = getModeParm();
    const isExtras = (bookType === BOOK_TYPE_EXTRAS);
    
    let thisParm = "";
    
    if (modeParm !== "") {
      thisParm = withSections ? "" : "&sections=false"
      thisParm = `${modeParm}${thisParm}`
    } else {
      thisParm = withSections? "" : "?sections=false"
    }
    let url = '';
    
    if (isExtras) {
      url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/general/${bookId}/movements${thisParm}`
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/${bookId}/movements${thisParm}`
    }
    // console.log("GetAllMovements", url);
    const { data, isPending, isError, error } = useQuery({
        queryKey: [bibleId,bookId,"movements"],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        enabled: true
    }
  );

  if (isError && isMVP && isExtras) {
    console.log("overriding MVP error")
    return { movements, isPendingMovements:false, isErrorMovements:false, error:null }
  }

  if (!isPending && data && data.data) {
    if (isMVP ) {
      if (data.data[0] && data.data[0].movements) {
        movements = data.data[0].movements;
      }
    } else {
      if (data.data.movements) {
        movements = data.data.movements;
        if (movements.length > 1) {
          movements.sort((a, b) => a.movement - b.movement);
          
        }
        if (movements.length > 0) {
          // Some movements have audio notes without a section - these are movement level notes and have to be treated differently
          movements.forEach(movement => {
            // console.log("GetAllMovements: movement.audio_notes:",movement.audio_notes)
            movementNotes[movement.id] = movement.audio_notes;
            // console.log("movementNotes[movement.id]:",movementNotes[movement.id])
            if (movementNotes[movement.id] && movementNotes[movement.id].length > 1) {
              movementNotes[movement.id].sort((a, b) => a.passage - b.passage);
            }
          })
        }
      }  
    }
  }
  let isPendingMovements = isPending;
  let isErrorMovements = isError;
  let errorMovements = error;
  return { movements, movementNotes, isPendingMovements, isErrorMovements, errorMovements }
}
