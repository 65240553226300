
import PropTypes from "prop-types";
import React from "react";
import { AppData } from "../../components/AppContext";
import "./style.css";

const headerTitle = process.env.REACT_APP_TITLE;
const headerSubTitle = process.env.REACT_APP_SUBTITLE;
export const HeroHeader = ({
  breakpoint,
  className,
  containerClassName,
  overlapGroupClassName,
  underlineClassName,
  underline = "/img/Underline.svg",
  headingClassName,
}) => {
  const { isOBTHelps } = AppData();
  const obtHelpsClassName = isOBTHelps?"obthelps":"";
  return (
    <div
      className={`obt-hero-header ${obtHelpsClassName} breakpoint-1-${breakpoint} ${className}`}
      data-breakpoints-mode={breakpoint === "tablet" ? "tablet" : breakpoint === "mobile" ? "mobile" : "desktop"}
    >
      <div className={`container ${containerClassName}`}>
        <div className="heading-wrapper">
            <div className="text-wrap">

              {["desktop", "tablet"].includes(breakpoint) && (
                
                <div className={`overlap-group-3 ${overlapGroupClassName}`}>
                  
                  <div  className={`heading ${headingClassName}`}>{headerTitle}</div>
                  <img
                    className={`underline underline-${breakpoint} ${underlineClassName}`}
                    alt="Underline" src={underline}
                  />
                </div>              
              )}

              {breakpoint === "mobile" && (
                <>
                  <div className={`heading`}>{headerTitle}</div>
                  <img className={`underline-${breakpoint}`} alt="Underline" src="/img/underline-mobile.png" />
                </>
              )}
            </div>
          

            <p className="supporting-text">
              {headerSubTitle}
            </p>
          
        </div>
      </div>
    </div>
  );
};

HeroHeader.propTypes = {
  breakpoint: PropTypes.oneOf(["desktop", "tablet", "mobile"]),
  underline: PropTypes.string,
};
