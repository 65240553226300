
import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { ObtPlaceholder } from "../ObtPlaceholder";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { BibleInfo } from "../../components/BibleInfo";
import "./style.css";

export const ObtButton = ({  
  size,
  hierarchy,
  stateProp,
  className="",
  textClassName="",
  text = "",
  bibleInfo = false,
  iconTrailing = false,
  iconLeading = false,
  iconOverride = <ObtPlaceholder className="obt-placeholder-instance" obtPlaceholder="/img/obt-placeholder.png" />,
  onClick
}) => {
  const [state, dispatch] = useReducer(reducer, {
    size: size || "sm",
    hierarchy: hierarchy || "secondary-color",
    state: stateProp || "default",
  });

  // Split button text at newlines (e.g., addresses, etc.)
  // Match the embedded \n or a literal string coming from env (\\n)
  const lines  = text.split(/(?:\n|\\n)/);

  return (
    <div
      className={`obt-button size-0-${state.size} ${state.hierarchy} state-3-${state.state} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
    >

      {iconLeading && <>{iconOverride}</>}

      <button className={`text-button ${textClassName}`} onClick={onClick}>
          {lines.map((line, index) => (
            <React.Fragment key={index} >
              {line}
              <br />
            </React.Fragment>
      ))}
      </button>
      
      {bibleInfo &&
        <>
        <IoIosInformationCircleOutline className="info-bible-icon"/> 
        <BibleInfo className="menu-block"></BibleInfo>
        </>
      }

      {iconTrailing && <>{iconOverride}</>}
      
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
      
    default:
  }

  return state;
}

ObtButton.propTypes = {
  iconTrailing: PropTypes.bool,
  iconLeading: PropTypes.bool,
  size: PropTypes.oneOf(["md", "xl", "lg", "sm"]),
  hierarchy: PropTypes.oneOf([
    "primary",
    "secondary-color",
    "secondary-gray",
    "tertiary-gray",   // Link with no border or background
    "link-color",      // Link with no border or background
    "link-gray"        // Link with no border or background
  ]),
  stateProp: PropTypes.oneOf(["default", "focused", "hover", "disabled"]),
  text: PropTypes.string,
};
