
// Returns a JSON structure on getItem and Saves the stringified JSON on setItem
// Saved as keyLabel
export const localSettings = (action, keyLabel=null, value=null) => {
    function isJsonString(str) {
        try {
            var json = JSON.parse(str);
            return (typeof json === 'object');
        } catch (e) {
            return false;
        }
    }

    if (keyLabel === undefined || keyLabel === null) {
        console.error("Missing keyLabel", keyLabel);
        return null;
    }
    
    switch (action) {
        case "setItem":
            localStorage.setItem(keyLabel, JSON.stringify(value));
            // console.log(keyLabel, value);
            return null;

        case "getItem":
            let savedSettings = [];
            let getSettings = localStorage.getItem(keyLabel);
            if (!isJsonString(getSettings)) {
                localStorage.removeItem(keyLabel);
                console.error("Not a valid JSON string", keyLabel, getSettings);
                return null;
            }
            if (getSettings) {
                savedSettings = JSON.parse(getSettings);
            }
            // console.log(keyLabel, savedSettings);
            return savedSettings;

        default:
            return null;
    }
}

/**
 * 
 * @returns bibleId from the local storage
 */
export const getBibleId = () => {
    let bible = localSettings('getItem', 'bible');
    if (bible !== undefined && bible !== null && bible.id !== undefined) {
      return bible.id; 
    } else {
      return null;
    }
  }