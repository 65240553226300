import React from "react";
import { VscExpandAll as ExpandIcon, VscCollapseAll as CollapseIcon } from "react-icons/vsc";

import "./style.css";

export const ObtExpandAll = ({ className, clickActions }) => {

  return (
    <div className={`obt-expand-all ${className}`}>
      <div className="expand-frame" >
        {clickActions && 
          <div className="expand-icon-frame">
            <span className="expand-tooltip tooltip">Expand All</span>
            <ExpandIcon className="obt-expand-all-icon" onClick={(e)=>clickActions(e,true)}/>
          </div>
        }
        {clickActions && 
          <div className="expand-icon-frame">
            <span className="expand-tooltip tooltip">Collapse All</span>
            <CollapseIcon className="obt-expand-all-icon" onClick={(e)=>clickActions(e,false)}/>
          </div>
        }

      </div>
    </div>
  );
};

