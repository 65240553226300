
import React from "react";
import { AppData } from "../../components/AppContext";
import "./style.css";

export const LanguageLabel = ({ className }) => {
  
  const { currentLanguage } = AppData();
  const languageId = currentLanguage['iso'];
  const haveLanguage = languageId !== undefined && languageId !== null && languageId !== ""?true:false;

  return (
    <>
      {haveLanguage && <div className={`language-label ${className}`} ><p className="language-text">{languageId}</p></div> }
    </>
  );
};


