import React, { useEffect, useState, useRef } from "react";
import Axios from "axios";
//import { createZip } from "../../utils/createZip.js";
import JSZip from 'jszip';
//import { ProgressBar } from "react-bootstrap/ProgressBar";
import { ObtProgressBar} from "../../components/ObtProgressBar"
import { MdCheckCircle, MdCancel } from "react-icons/md";
import "./style.css";

export const Downloader = ({ files = [], remove }) => {
  return (
    <div className="downloader">
      <div className="card">
        <div className="card-header">File Downloader</div>
        <ul className="file-list-group">
          {files.map((file) => (
            <DownloadItem
              key={file.downloadId}
              removeFile={() => remove(file.downloadId)}
              {...file}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

const DownloadItem = ({ filelist, filename, removeFile }) => {

  // assign to useRef to eliminate dependency arning on useEffect
  const thisRemoveFile = useRef(removeFile); 
  thisRemoveFile.current = removeFile;

  // filelist contains the list of sections, with name and url
  // assign to useRef to eliminate dependency arning on useEffect
  const thisFileList = useRef(filelist);
  thisFileList.current = filelist;

  // assign to useRef to eliminate dependency arning on useEffect
  const thisFileName = useRef(filename);
  thisFileName.current = filename;

  const controllerRef = useRef();
  if (!controllerRef.current) { 
    controllerRef.current  = new AbortController(); 
  }

  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
    abort: false,
    error: false,
    skip: false,
    errormessage: null
  });

  useEffect(() => {
    //console.log('Downloader rendering: ', filelist);
    //let skippedFiles = false;

    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        setDownloadInfo({
          progress: (total && total>0)?Math.floor((loaded * 100) / total):0,
          loaded,
          total,
          completed: false,
          abort: false,
          error: false,
          skip: false,
          errormessage: null
        });
      },
    };
  
    const downloadFileNow = (file) => {

      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", thisFileName.current);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();

      setDownloadInfo((info) => ({
        ...info,
        completed: true,
      }));

      //let timeoutSeconds = skippedFiles ? 20000 : 5000;
      let timeoutSeconds = 5000;
      setTimeout(() => {
        thisRemoveFile.current();
      }, timeoutSeconds);
    }
  
    const fetchFiles = async (fileList) => {
      let errorMessage = null;

      try {
        if (fileList.length > 0) {
            Promise.all(fileList.map( async file => {
              // console.log("fetching file:", file)
              return (                
                await Axios.get(file.url, {
                  signal: controllerRef.current.signal,
                  responseType: "blob",
                  ...options,
                  }
                ).then(function (response) {
                  if (response.status === 200) {                                        

                    let data = response.data;
                    // console.log("Good data:",data);
                    return ({ ...file, data })

                  } else {
                    errorMessage = `HTTP error fetching file! Status: ${response.status}`;
                    console.error(errorMessage);
                    setDownloadInfo((info) => ({
                      ...info,
                      error: true,
                      errormessage: errorMessage
                    })); 
                    return null;
                  }

                }).catch((e) => {
                  if (!Axios.isCancel(e)) {
                    if (e.response?.status === 403 || e.response?.status === 404) {
                      errorMessage = `${file.url?file.url:""} : ${e.code} : ${e.message}`;
                      console.error('Skipping bad file: ', errorMessage);
                      //skippedFiles = true;
                      // setDownloadInfo((info) => ({
                      //   ...info,
                      //   skip: true,
                      //   errormessage: errorMessage
                      // })); 
                      let data = e.response?.data;
                      console.log("Bad data:",file, data);
                      return ({ ...file, data })
                    } else {
                      console.error('Error fetching file:', file, e);
                      errorMessage = `Error fetching file: ${e.code} : ${e.message}`;
                      setDownloadInfo((info) => ({
                        ...info,
                        error: true,
                        errormessage: errorMessage
                      }));
                    }
                  } else {
                    console.log('Axios aborted download')
                    setDownloadInfo((info) => ({
                      ...info,
                      abort: true
                    }));
                  }
                })
              ) 
            }))
            .then( downloads => {
              if (downloads && downloads.length > 0 && downloads[0] !== undefined && downloads[0] !== null) {
              //console.log('downloads', downloads);
              // Only zip if more othan one file
              let thisName = "";
              let thisData = null;
              if (downloads.length > 1) {
                const zip = new JSZip();
                downloads.forEach((download) => {
                  if (download) {
                    // console.log("Zip file: ", download.name);
                    thisName = download.name?download.name:"";
                    thisData = download.data?download.data:null;
                    if (thisData) {
                      zip.file(`${thisName}`, download.data);
                    }
                  }
                })

                zip.generateAsync({type: "blob",streamFiles: true})
                .then( zipFile =>  {
                    downloadFileNow(zipFile)
                })
              } else {
                downloadFileNow(downloads[0].data);
              }
              
            }
            })
            .catch((e) => {
              console.error('Error creating zip file', thisFileList.current, e);
              errorMessage = `Error creating zip file: ${e.code} : ${e.message}`; 
              setDownloadInfo((info) => ({
                ...info,
                error: true,
                errormessage: errorMessage
              }));
              //return null;
            })
          }        
      
        } catch (e) {
          console.error('Error fetching files', thisFileList.current, e);
          errorMessage = `Error fetching files: ${e.code} : ${e.message}`;
          setDownloadInfo((info) => ({
            ...info,
            error: true,
            errormessage: errorMessage
          }));
        }
    }
    
    // Executes only on mount:
    fetchFiles(thisFileList.current);
          
  },[]);

  // Allow user to cancel the request for any given file
  function handleCancel () {
    console.log('Cancelling', filename, controllerRef.current);
    
    if (controllerRef.current) {
      console.log('aborting');
      controllerRef.current.abort();
    }

    setDownloadInfo((info) => ({
      ...info,
      abort: true,
    }));

    setTimeout(() => {
      thisRemoveFile.current();
    }, 4000);
   
  }
  // Remove the file entry from the download list
  function handleClose () {
    // console.log('Closing file ', filename);
    removeFile();
  }
  

  const formatBytes = (bytes) => {
    let finalBytes = bytes / (1024 * 1024);
    if (finalBytes === undefined || isNaN(finalBytes) || finalBytes === null || bytes <= 0) {
      return "--";
    } else {      
      return `${finalBytes.toFixed(2)} MB`;
    }
    
  }

  return (
    <li className="list-group-item">
      <div className="row">
        <div>
          <div className="downloader-filename">
            {thisFileName.current}
          </div>
          <div className="downloader-remaining">
            {/* <small> */}
              {!downloadInfo.error && downloadInfo.loaded > 0 && (
                <>
                  <span className="downloader-text">
                    <span className="text-success">
                      {formatBytes(downloadInfo.loaded)}
                    </span>
                    / {formatBytes(downloadInfo.total)}
                  </span>
                  <MdCancel onClick={handleCancel}/>
                </>
              )}

              {!downloadInfo.error && downloadInfo.loaded === 0 && 
                <>
                  <span className="downloader-text">Initializing... </span>
                  <MdCancel onClick={handleCancel}/>
                </>
              }
            {/* </small> */}
          </div>
          <div className="downloader-complete">
            
            {!downloadInfo.error && downloadInfo.completed && (
              <span className="text-success">
                Completed <MdCheckCircle />
              </span>
              // <>
              // {!downloadInfo.skip &&
              //   <span className="text-success">
              //     Completed <MdCheckCircle />
              //   </span>
              // }
              // {downloadInfo.skip &&
              //   <span className="text-error">
              //     {`Download completed but skipped bad file(s):  ${downloadInfo.errormessage}`}
              //     <MdCancel onClick={handleClose}/>
              //   </span>
              // }
              // </>
            )
            }
            {downloadInfo.abort && (
              <span className="text-abort">
                File download canceled.
              </span>
            )}
            {downloadInfo.error && (
              <span className="text-error">
                {`Unable to complete file download.  ${downloadInfo.errormessage}`}
                <MdCancel onClick={handleClose}/>
              </span>
            )}
            
          </div>
        </div>
        <div className="downloader-progress-bar">
          
            <ObtProgressBar  progress={`${downloadInfo.progress}%`} />
          
        </div>
      </div>
    </li>
  );
};
