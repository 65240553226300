// downloadFunctions.js

/**
 * 
 * @param cardId : id of current resource card
 */
export const downloadAudio = (type, fileDownloader, list, bookId, downloadName) => {
  // console.log("downloadAudio - type, bookId, downloadName, list:",type, bookId, downloadName, list)
  switch (type) {
    
    case "list":
      // Download a single file - not zipped
      downloadList(fileDownloader,list,bookId, downloadName);
      break;
    
    case "section":
      // Download a section as a zipped file, includes passages and audio notes
      downloadSection(fileDownloader,list,bookId,downloadName);
      break;

    case "chapter":
      // Download a chapter as a zipped file, includes passages and audio notes
      downloadChapter(fileDownloader,list,bookId,downloadName);
      break;

    case "movement":
      // Download a movement as a zipped file, includes passages and audio notes
      downloadMovement(fileDownloader,list,bookId,downloadName);
      break;

    case "book":
      // Download entire book as a zipped file
      break;

    default:
      break;
  }
  return;

}

const downloadMovement = (fileDownloader, list, bookId, downloadName) => {
  //console.log("Downloading movement", list);
  if (list && list.length > 0) {
    downloadList(fileDownloader, list, bookId, downloadName)
  }
}

const downloadChapter = (fileDownloader, list, bookId, downloadName) => {
  // console.log("Downloading chapter", list);
  if (list && list.length > 0) {
    // console.log("chapter card:",list[0])
    downloadList(fileDownloader, list, bookId, downloadName)
  }
}

const downloadSection = (fileDownloader, list, bookId, downloadName) => {
  // console.log("Downloading section", list);
  if (list && list.length > 0) {
    // console.log("chapter card:",list[0])
    downloadList(fileDownloader, list, bookId, downloadName)
  }
}

/**
 * 
 * @param {*} fileDownloader : instance of useFileDownloader()
 * @param {*} downloadList : list of files to download in the format built by the Sections and ResourceSecions components
 * @param {string} downloadName : Name for download file.  If null we will use the name of the first item found.
 *          {
 *            "key": unique key for the book
              "id": id for this type of card as returned by the API
              "index": index in the playlist,
              "section": section index in the playlist (may not be used)
              "sectionId": section id
              "title": title / name
              "passageAudio": list of audio files with chapters and titles filtered by selected categories 
              "passageAudioUnfiltered": list of audio files
              "passageFileName": list of audio file names with chapters and titles filtered by selected categories 
              "passageFileNameUnfiltered": list of audio file names
              "image": image file,
              "audioTitle": title audio,
              "passage": if note, this is the passage number
              "category": category name for notes,
              "categoryId": category id for notes,
              "color": color for note category,
              "duration": play duration
              "show":true/false to show this card - used to filter notes by categories
            }
 * 
 */
const downloadList = (fileDownloader, downloadList, bookId, overrideName=null) => {

    //console.log("Downloading list:", downloadList);
    let exportList = [];
    let fileCount = 0; 
    let downloadName = null;
    if (overrideName) {
      downloadName = bookId + "-" + overrideName;
    }
    let item = null;
    for (let i = 0; i < downloadList.length; i++) {
      item = downloadList[i];
      let count = item.passageFileNameUnfiltered?item.passageFileNameUnfiltered.length:0;
      if (downloadList.length === 1 && count === 1) {
        downloadName = bookId  + "-" + item.passageFileNameUnfiltered[0];
      } 
      if (!downloadName) {downloadName = bookId  + "-" + item.title};
      for (let j = 0; j < count; j++) {
        // console.log("downloadName", downloadName)
        // console.log("item.passageFileNameUnfiltered[j]",item.passageFileNameUnfiltered[j])
        // console.log("item.passageAudioUnfiltered[j]",item.passageAudioUnfiltered[j])
        exportList[fileCount]={};
        exportList[fileCount]["name"] = item.passageFileNameUnfiltered[j];
        exportList[fileCount]["url"] = item.passageAudioUnfiltered[j];
        fileCount++;
      }
    }
    
    // console.log("exportList:", exportList)
    // Download all the section passages into a single zip file
    // Use the section name as the zip file name
    if (exportList.length > 0) {

      if (exportList.length > 1) {
        if (!downloadName) {
          downloadName = "section-download.zip"
        } else {
          downloadName = `${downloadName}.zip`
        }
      } 

      let file={};
      file.filelist = exportList;
      file.filename = downloadName;

      fileDownloader(file);
    }
}