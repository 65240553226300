
import React from "react";
import { ElementCard } from "../ElementCard";
import { AppData } from "../../components/AppContext";
import { GetAllChapters } from "../../utils/GetAllChapters";
import "./style.css";

const placeholderCards = [  
  {
    chapter: 1,
    title: "_"
  },
  {
    chapter: 2,
    title: "_"
  }
];

export const Chapters = ({ className }) => {
  const { storyMode, viewState, currentBible, currentBook, setCurrentChapter, currentImage } = AppData();
  const bibleId = currentBible['id'];
  const { bookId, chapters, isPendingChapters, isErrorChapters, errorChapters } = GetAllChapters(bibleId, currentBook);
    
  // TBD: Add better error handling
  if (isErrorChapters) return 'Error fetching chapters: ' + errorChapters.message

  return (
    <div className={`chapters ${storyMode} ${viewState} ${className}`}>
      <div className="frame-chapters">

        {isPendingChapters && placeholderCards.map(card => {          
          return (
            <span className="skeleton-box" key={card.chapter}>
              <ElementCard
                key={`${currentBook}-Movement-${card.chapter}`}
                cardType="movement"
                cardName={card.title}
                cardAnimation = "shimmer"
                cardIndex={card.chapter}
                cardDuration={null}
                cardImageClassName={`card-image-${viewState}`}
                cardAudioTitle={null}
                cardState={viewState}  
                showPlay={false}
                showDownload={false}
                obtDownloadClassName={`card-download-${viewState}`}
                obtQuickPlayClassName={`card-quickplay-${viewState}`}
                overlapGroupClassName="instance-node"
                subheadingClassName="element-card-subheading"
                showAddToPlayList={false}
              />
            </span>
          )
        })}

        {!isPendingChapters && chapters && chapters.map(card => {
              return (
              <ElementCard
                key={`${bookId}-Chapter-${card.chapter}`}
                cardType="chapter"
                cardName={card.title}
                cardIndex={card.chapter}
                cardDuration={card.duration}
                cardMovement={card.movement_start}
                cardImageClassName={`card-image-${viewState}`}
                cardImage={card.images[0]?card.images[0].url?card.images[0].url:currentImage:currentImage}
                cardAudioTitle={card.title_audio?card.title_audio[0]?.url?card.title_audio[0]?.url:null:null}
                cardState={viewState}
                showPlay={false}
                showDownload={false}
                obtDownloadClassName={`card-download-${viewState}`}
                obtQuickPlayClassName={`card-quickplay-${viewState}`}
                overlapGroupClassName="instance-node"
                subheadingClassName="element-card-subheading"                 
                navigateTo="/sections" 
                setCurrentCardAction={() => setCurrentChapter}
                showAddToPlayList={true}
              />
              )
            })}
      </div>      
    </div>
  );
};
