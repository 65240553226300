import { localSettings, getBibleId } from "./localSettings.js";

/**
 * 
 * @param {*} bibleId : Bible ID for these categories
 * @returns categories : array of categories to save to Local Storage
 */
export const getLocalCategories = (bibleId) => {
  // Retrieve saved categories from local storage
  if (bibleId !== undefined && bibleId !== null && bibleId !== "") {
    let categoryTag = `categories-${bibleId}`;
    let categories = localSettings('getItem', categoryTag);
    return categories;
  } else {
    console.error("No Bible ID provided. Unable to retrieve categories.");
    return [];
  }
}

/**
 * 
 * @param {*} bibleId : Bible ID for these categories
 * @param {*} categories : array of categories to save to Local Storage
 */
export const setLocalCategories = (bibleId, categories) => {
  // Retrieve saved categories from local storage
  let categoryTag = `categories-${bibleId}`;
  // console.log("setLocalCategories categories: ", categories);
  // Save the updated categories in the browser's local storage
  localSettings('setItem', categoryTag, categories);
}

/**
 * 
 * @param {*} bibleId : Bible ID for these categories 
 * @param {*} categoryId : unique id for the category to be toggled
 * @returns 
 */
export const getCategoryToggle = (bibleId, categoryId) => {
  if (bibleId === undefined || bibleId === null || bibleId === "" ||
    categoryId === undefined || categoryId === null || categoryId === "") {
    console.log("getCategoryToggle - ignoring invalid input value")
    return true;
  }
  // Retrieve saved categories from local storage  
  let savedCategories = getLocalCategories(bibleId);  
  
  let index = -1;
  let toggleValue = true;  // Assume category is enabled unless proven otherwise
  if (savedCategories && savedCategories.length>0) {
    index = savedCategories.findIndex(cat => cat.id === categoryId);      
  }
  //return (index >= 0) ? savedCategories[index]?.toggle : true;
  if (index >= 0) {
    toggleValue = savedCategories[index]?.toggle;
  }
  // Treat missing value as true rather than false to make sure the category is not skipped
  toggleValue = (toggleValue === undefined || toggleValue === null)?true:toggleValue;
  // console.log("specialUseCategory - toggleValue: " , toggleValue);
  return toggleValue;
}

/**
 * 
 * @param {*} bibleId : Bible ID for these categories 
 * @param note : audio note structure - must have .note_category_id
 * @returns category object that matches the name or NULL if not found
 * Assumes BIbleId is set in the AppContext
 */
export const mapCategory = (bibleId,note) => {  

    if (note === undefined || note === null || note.note_category_id === undefined || note.note_category_id === null) {
      console.error("mapCategory: Invalid card", note);
      return null;
    }
    if (bibleId === undefined || bibleId === null) {
      console.error("mapCategory: No curent Bible selected.");
      return null;
    }
    // Retrieve saved categories from local storage
    let categoryList = getLocalCategories(bibleId);
    return categoryList.find(card => card.id === note.note_category_id);
}

/**
 * 
 * @param {*} bibleId : Bible ID for these categories 
 * @param {*} categoryId : ID of category to toggle
 * @returns 
 */
export const toggleCategory = (bibleId, categoryId) => {

    // Retrieve saved categories from local storagel
    let categories = getLocalCategories(bibleId);

    if (categories === undefined || categories === null) {
      return null;
    }
    if (categoryId >= 0) {    
      
      let found = false;
      let newCategories = categories.map(card => {
        if (!found && card.id === categoryId) {
          // Toggle the state
          found = true;
          return { ...card, toggle: card.toggle?!card.toggle:true };
        } else {
          // No changes
          return card;
        }
      });
      if (!found) {
        console.error("toggleCategory categoryId not found: ",categoryId);
      }
      
      //console.log("toggleCategory setLocalCategories: ",bibleId, newCategories);
      setLocalCategories(bibleId,newCategories);
      return newCategories;
    }
}

/**
 * 
 * @param {*} useValue - specialUse value to be matched against
 * @returns false if the cateogry match is found and the category is disabled
 *          true for all other conditions to ensure category is not ignored
 */
export const isSpecialUseCategory = (useValue)  => {
    
    // Check if input values are valid before proceeding
    if (useValue === undefined || useValue === null || useValue === "") {
        console.log("isSpecialUseCategory - ignoring invalid input value")
        return true;
    }

    let bibleId = getBibleId();
    if (bibleId) {
      // Retrieve saved categories from local storage
      let savedCategories = getLocalCategories(bibleId);
      // console.log("savedCategories", savedCategories);
      let index = -1;
      let toggleValue = true;  // Assume category is enabled unless proven otherwise
      if (savedCategories && savedCategories.length>0) {
        // Search for a match between the specialUse indicator and the input value
        index = savedCategories.findIndex(cat => cat.specialUse !== undefined && cat.specialUse === useValue);
        // console.log("isSpecialUseCategory index = ", index);
      }
      if (index >= 0) {
        toggleValue = savedCategories[index]?.toggle;
      }
      // Treat missing value as true rather than false to make sure the category is not skipped
      toggleValue = (toggleValue === undefined || toggleValue === null)?true:toggleValue;
      // console.log("specialUseCategory - toggleValue: " , toggleValue);
      return toggleValue;
    } else {
      console.error("isSpecialUseCategory: No Bible ID to get cetgory information");
      return true;
    }
}
