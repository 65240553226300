import React  from "react";
// import { StrictMode }  from "react";
import { createRoot } from 'react-dom/client';
import { App } from "./App";
import { AppWrapper} from "./components/AppContext";
import { PlaylistProvider} from "./components/AudioPlayListContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./styleguide.css";
import './index.css';

const domNode = document.getElementById('root');
const root = createRoot(domNode);
const queryClient = new QueryClient();

root.render(      
    
    <AppWrapper>
        <PlaylistProvider>
            <QueryClientProvider client={queryClient}>
                {/* <StrictMode> */}
                    <App />
                {/* </StrictMode>   */}
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
        </PlaylistProvider>
    </AppWrapper>
    );

