// AppLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import { AudioControls } from './components/AudioControls';

const AppLayout = () => {
  
  return (
    
      <div>
        <main>
          <Outlet />  {/* Child components will render here */}
        </main>
        <AudioControls />
      </div>    
  );
};

export default AppLayout;