import React from "react";
import "./style.css";

export const ObtNavMenuItem = ( {  
  breakpoint="desktop",
  className="obt-nav-menu-item-instance",
  stateProp="default",
  icon=null,
  text,
  subtext=null,
  onClick=null
}) => {
  return (
    <div className={`obt-nav-menu-item`} > 
      {icon && <img className="icon" alt="Icon" src={icon} onClick={onClick}/>}
      <div className="content">
        <div className="text-and-supporting">
          <div className="text-and-badge">
            <div className="text" onClick={onClick}>{text}</div>
          </div>
          {subtext && <div className="supporting-text">{subtext}</div>}
        </div>
      </div>
    </div>
  );
};
