import React from "react";
import "./style.css";

export const ObtProgressBar = ( { className, progress=0 } ) => {
  return (
    <div className={`progress-bar-outer ${className}`}>
        <div className={`progress-bar-inner`} style={{'--width': progress}}>
            {progress}
        </div>
    </div>
  );
};